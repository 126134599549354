<template>
  <div>
    <el-form :model="formItem" ref="formItem" label-width="100px">
      <el-form-item :label="$t('normal.answer')">
        <el-input v-model="formItem.answer"></el-input>
      </el-form-item>
      <el-form-item :label="$t('normal.diff')">
        <el-rate v-model="formItem.diff"></el-rate>
      </el-form-item>
      <el-form-item :label="$t('normal.score')" v-show="!formItem.is_item">
        <el-input-number v-model="formItem.score" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('question.parse')">
        <vue-ueditor-wrap v-model="formItem.content" :config="myConfig" style="width: 100%"></vue-ueditor-wrap>
      </el-form-item>
      <el-form-item :label="$t('question.questionBody')">
        <vue-ueditor-wrap v-model="formItem.item_content" :config="myConfig" style="width: 100%"></vue-ueditor-wrap>
      </el-form-item>
      <el-form-item style="margin-top: 10px;">
        <el-button type="primary" @click="onSubmit('formItem')" plain>提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
import VueUeditorWrap from 'vue-ueditor-wrap'
import { ueditorPaper } from '@/plugins/pluginsConfig'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  components: {
    VueUeditorWrap
  },
  data () {
    return {
      formItem: {
        content: '',
        diff: 3,
        answer: '',
        item_content: '',
        id: 0,
        score: 0
      },
      status: {
        knowledge: []
      },
      myConfig: ueditorPaper
    }
  },
  methods: {
    onSubmit () {
      this.$refs.formItem.validate((valid) => {
        if (valid) {
          this.$msgbox({
            title: this.$t('normal.tip'),
            message: this.$t('normal.tipDes'),
            confirmButtonText: this.$t('button.confirm'),
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true
                instance.confirmButtonText = this.$t('normal.process')
                instance.title = this.$t('normal.processTitle')
                instance.message = this.$t('normal.processMessage')
                setTimeout(() => {
                  done()
                  setTimeout(() => {
                    instance.confirmButtonLoading = false
                  }, 300)
                }, 1000)
              } else {
                done()
              }
            }
          }).then(action => {
            if (action === 'confirm') {
              this.postFetch('/api/question_item/edit', this.formItem, { needSuccess: true }).then((response) => {
                this.$router.replace({ path: '/question_item/index', query: { question_id: this.$route.query.question_id } })
              })
            }
          })
        } else {
          this.$message({
            message: this.$t('normal.validata'),
            type: 'error'
          })
        }
      })
    }
  },
  async created () {
    // eslint-disable-next-line
    uParse('.content', {
      'liiconpath': '/ueditor/themes/ueditor-list/'
    })
    this.status.knowledge = await this.$store.dispatch('getOption', 'knowledge')
    this.formItem.id = this.$route.query.id
    this.getFetch('/api/question_item/getone', this.formItem).then((response) => {
      this.formItem.item_content = response.item_content
      this.formItem.content = response.content
      this.formItem.diff = response.diff
      this.formItem.answer = response.answer
      this.formItem.is_objective = response.is_objective
      this.formItem.score = response.score
    })
  }
}
</script>
