<template>
  <div>
    <el-form :model="formItem" ref="formItem" label-width="100px">
      <el-form-item :label="$t('normal.grade')">
        <el-select v-model="formItem.grade">
          <el-option
            v-for="item in status.grade"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('question.questionType')">
        <el-select v-model="formItem.question_type">
          <el-option
            v-for="item in status.questionType"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('question.questionFrom')">
        <el-select v-model="formItem.question_from">
          <el-option
            v-for="item in status.questionFrom"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('question.questionTo')">
        <el-input v-model="formItem.question_to"></el-input>
      </el-form-item>
      <el-form-item :label="$t('question.questionBody')">
        <vue-ueditor-wrap v-model="formItem.title" :config="myConfig" style="width: 100%"></vue-ueditor-wrap>
      </el-form-item>
      <div>
        <el-form v-for="(sub, index) in formItem.item" :key="index" label-width="100px" style="margin-top: 10px;">
          <el-form-item :label="$t('question.subQuestion') + (index + 1) + ':'"></el-form-item>
          <el-form-item :label="$t('knowledge.index')">
            <el-select v-model="formItem.item[index].knowledge_id[subIndex]" v-for="(filter, subIndex) in formItem.item[index].knowledge_id" :key="subIndex" @change="knowledge('sub', index, subIndex)">
              <el-option
                v-for="item in status.knowledge"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <div style="font-size: 29px;width: 35px;" @click="addKnowledge('sub', index)">+</div>
          </el-form-item>
          <el-form-item :label="$t('normal.answer')">
            <el-input v-model="formItem.item[index].answer"></el-input>
          </el-form-item>
          <el-form-item :label="$t('normal.diff')">
            <el-rate v-model="formItem.item[index].diff"></el-rate>
          </el-form-item>
          <el-form-item :label="$t('normal.score')">
            <el-input-number v-model="formItem.item[index].score"></el-input-number>
          </el-form-item>
          <el-form-item :label="$t('question.parse')">
            <vue-ueditor-wrap v-model="formItem.item[index].content" :config="myConfig" style="width: 100%"></vue-ueditor-wrap>
          </el-form-item>
          <el-form-item :label="$t('question.questionBody')">
            <vue-ueditor-wrap v-model="formItem.item[index].item_content" :config="myConfig" style="width: 100%"></vue-ueditor-wrap>
          </el-form-item>
        </el-form>
        <div style="font-size: 29px;" @click="addKnowledge('item')">+</div>
      </div>
      <el-form-item style="margin-top: 10px;">
        <el-button type="primary" @click="onSubmit('formItem')" plain>提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { status } from '@/plugins/permission'
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
import VueUeditorWrap from 'vue-ueditor-wrap'
import { ueditorPaper } from '@/plugins/pluginsConfig'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  components: {
    VueUeditorWrap
  },
  data () {
    return {
      formItem: {
        title: '',
        question_type: 0,
        grade: 1,
        item: [{
          knowledge_id: [0],
          content: '',
          item_content: '',
          diff: 3,
          answer: '',
          score: 5
        }],
        question_from: 1,
        question_to: ''
      },
      status: {
        grade: status.grade,
        questionType: [],
        knowledge: [],
        questionFrom: status.questionFrom
      },
      myConfig: ueditorPaper
    }
  },
  methods: {
    addKnowledge (w, i) {
      if (w === 'sub') {
        if (this.formItem.item[i].knowledge_id.indexOf(0) === -1) {
          this.formItem.item[i].knowledge_id.push(0)
        }
      } else if (w === 'item') {
        this.formItem.item.push({
          knowledge_id: [0],
          content: '',
          item_content: '',
          diff: 3,
          answer: '',
          is_objective: false,
          score: 0
        })
      }
    },
    knowledge (w, index, subIndex) {
      if (w === 'sub') {
        const knowledgeId = this.formItem.item[index].knowledge_id[subIndex]
        const tmp = []
        for (let i = 0; i < this.formItem.item[index].knowledge_id.length; i++) {
          if (i === subIndex) continue
          if (this.formItem.item[index].knowledge_id[i] === knowledgeId) {
            tmp.push(i)
          }
        }
        for (let i = 0; i < tmp.length; i++) {
          this.formItem.knowledge_id.splice(tmp[i], 1)
        }
      }
    },
    onSubmit () {
      this.$refs.formItem.validate((valid) => {
        if (valid) {
          this.$msgbox({
            title: this.$t('normal.tip'),
            message: this.$t('normal.tipDes'),
            confirmButtonText: this.$t('button.confirm'),
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true
                instance.confirmButtonText = this.$t('normal.process')
                instance.title = this.$t('normal.processTitle')
                instance.message = this.$t('normal.processMessage')
                setTimeout(() => {
                  done()
                  setTimeout(() => {
                    instance.confirmButtonLoading = false
                  }, 300)
                }, 1000)
              } else {
                done()
              }
            }
          }).then(action => {
            if (action === 'confirm') {
              this.$localStorage.set('questionTmp', JSON.stringify(this.formItem))
              this.postFetch('/api/question/add', this.formItem, { needSuccess: true }).then((response) => {
                this.$router.replace({ path: '/question/index', query: this.$route.query })
              })
            }
          })
        } else {
          this.$message({
            message: this.$t('normal.validata'),
            type: 'error'
          })
        }
      })
    }
  },
  async created () {
    // eslint-disable-next-line
    uParse('.content', {
      'liiconpath': '/ueditor/themes/ueditor-list/'
    })
    this.status.questionType = await this.$store.dispatch('getOption', 'question_type')
    if (this.status.questionType.length > 0) this.formItem.question_type = this.status.questionType[0].value
    let questionTmp = this.$localStorage.get('questionTmp1')
    if (questionTmp) {
      questionTmp = JSON.parse(questionTmp)
      if (questionTmp.item) {
        if (questionTmp.item.length > 1) {
          questionTmp.item = [questionTmp.item[0]]
        }
        if (questionTmp) {
          this.formItem = questionTmp
        }
      }
    }
    this.status.knowledge = await this.$store.dispatch('getOption', 'knowledge')
    if (this.formItem.item[0].knowledge_id === 0) {
      if (this.status.knowledge.length > 0) this.formItem.item[0].knowledge_id[0] = this.status.knowledge[0].value
    }
  }
}
</script>
