<template>
  <div>
    <div v-if="addButton || addPath" style="margin-bottom: 15px;">
      <el-button type="primary" size="small" @click.native.prevent="$router.push({ path: addPath.url, query: $route.query })" plain v-if="addPath.url">{{$t(addPath.text)}}</el-button>
      <el-button type="primary" v-for="(item, index) in addButton" :key="index" size="small" @click.native.prevent="addButtonDo(item)" plain>{{$t(item.text)}}</el-button>
    </div>
    <el-form :inline="true" :model="formInline" @submit.native.prevent size="mini">
      <el-form-item :label="$t(filter.label)" v-for="(filter,index) in filters" :key="index">
        <el-select
          :remote-method="filter.remoteMethod"
          :filterable="filter.filterable"
          :remote="filter.filterable"
          :reserve-keyword="filter.filterable"
          v-model="formInline[filter.value]"
          :multiple="filter.multiple"
          @change="onSubmit"
          v-if="filter.options">
          <el-option
            v-for="item in filter.options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker
          v-else-if="filter.type === 'datetime'"
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="formInline[filter.value]"
          align="right"
          :picker-options="pickerOptions"
          type="datetimerange"
          range-separator="-"
          @change="onSubmit">
        </el-date-picker>
        <el-input v-model="formInline[filter.value]" :type="filter.type" v-else @keyup.enter.native="onSubmit"></el-input>
      </el-form-item>
    </el-form>
    <el-table :data="data" highlight-current-row v-loading.body="loading" element-loading-text="拼命加载中" border stripe height="650">
      <el-table-column prop="teacher_name" label="姓名" width="65" fixed></el-table-column>
      <el-table-column
        prop="num5"
        label="主课"
        width="50">
      </el-table-column>
      <el-table-column
        prop="num4"
        label="早读"
        width="50">
      </el-table-column>
      <el-table-column
        prop="num3"
        label="午读"
        width="50">
      </el-table-column>
      <el-table-column
        prop="num2"
        label="畅游书海"
        width="65">
      </el-table-column>
      <el-table-column
        prop="num1"
        label="口算"
        width="50">
      </el-table-column>
      <el-table-column
        prop="num6"
        label="早操"
        width="50">
      </el-table-column>
      <el-table-column
        prop="num7"
        label="课间操"
        width="50">
      </el-table-column>
      <el-table-column
        prop="num8"
        label="特色课"
        width="50">
      </el-table-column>
      <el-table-column
        prop="name"
        label="明细">
        <template slot-scope="scope">
          <span v-for="(item, index) in scope.row.lessons" :key="index">{{item.lesson_name}}: {{Math.floor(item.num * item.conf * 100) / 100}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </template>
      </el-table-column>
    </el-table>
    <div>主课：{{nums.num5}}|早读：{{nums.num4}}|午读：{{nums.num3}}|畅游书海：{{nums.num2}}|口算：{{nums.num1}}</div>
    <el-dialog :visible.sync="isShowImageDialog" @closed="isShowImageDialog = false" width="90%">
      <el-carousel indicator-position="outside" style="width: 100%" height="1200px">
        <el-carousel-item v-for="src in showImageList" :key="src">
          <img :src="src" style="max-width: 100%;max-height: 100%;display: block; margin: 0 auto;"/>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  data () {
    const o = this.$route.path.split('/')
    const controller = (o[1] ? o[1] : undefined)
    const method = (o[2] ? o[2] : undefined)
    return {
      controller,
      method,
      loading: true,
      addPath: {},
      addButton: [],
      filters: [],
      tableItems: [],
      formInline: {
        limit: 10,
        page: 1
      },
      pageTotal: 0,
      data: [],
      buttonItems: [],
      isShowImageDialog: false,
      showImageList: [],
      changeUrl: '/api/' + controller + '/edit',
      getDataUrl: '/api/' + controller + '/index',
      lessons: [],
      nums: {
        num1: 0,
        num2: 0,
        num3: 0,
        num4: 0,
        num5: 0
      }
    }
  },
  methods: {
    buttonWidth (res) {
      return 230
    },
    addButtonDo (item) {
      if (item.type === 'route') {
        this.$router.push({ path: item.path, query: item.query })
      } else if (item.type === 'download') {
        this.getFetch(item.path, this.formInline).then((response) => {
          if (item.component) {
            response = item.component(response)
          }
          let a = document.createElement('a')
          const objectUrl = URL.createObjectURL(new Blob([response], { type: 'text/csv,charset=UTF-8' }))
          a.setAttribute('href', objectUrl)
          a.setAttribute('download', new Date().toDateString() + '.csv')
          a.click()
          a.remove()
        })
      } else if (item.type === 'popup') {
        this.getFetch(item.path, this.formInline).then((response) => {
          this.$notify(item.component(response))
        })
      }
    },
    formalView (row, value) {
      value = value.split('|')
      let word = ''
      for (let i in value) {
        if (row[value[i]] || row[value[i]] === 0) {
          word += row[value[i]]
        }
      }
      return word
    },
    getUrl (path, row) {
      let word = /%\S*?%/.exec(path)
      if (word === null) {
        return path
      } else {
        const v = this.$validator.trim(word[0], '%')
        if (row[v]) {
          path = path.replace(/%\S*?%/, row[v])
        } else {
          path = path.replace(/%\S*?%/, '')
        }
        return this.getUrl(path, row)
      }
    },
    buttonDone (row, buttonItem) {
      if (buttonItem.type === 'ajax') {
        this.getFetch(this.getUrl(buttonItem.path, row), null, { needSuccess: true }).then(response => {
          this.getData()
        })
      } else if (buttonItem.type === 'route') {
        this.$router.push(this.getUrl(buttonItem.path, row))
      } else if (buttonItem.type === 'contentAjax') {
        this.$prompt(this.$t(buttonItem.inputTitle), this.$t('normal.tip'), {
          confirmButtonText: this.$t('button.confirm'),
          cancelButtonText: this.$t('button.cancel')
        }).then(({ value }) => {
          this.getFetch(this.getUrl(buttonItem.path, row) + '&content=' + value, null, { needSuccess: true }).then(response => {
            this.getData()
          })
        })
      } else if (buttonItem.type === 'jump') {
        window.location.href = buttonItem.path
      }
    },
    checkOption (value, option) {
      for (let i = 0; i < option.length; i++) {
        if (option[i].value === value) {
          return option[i].label
        }
      }
    },
    onSubmit () {
      this.formInline.page = 1
      this.getData()
    },
    changePage (page) {
      this.formInline.page = page
      this.getData()
    },
    getData () {
      this.nums.num1 = 0
      this.nums.num2 = 0
      this.nums.num3 = 0
      this.nums.num4 = 0
      this.nums.num5 = 0
      this.nums.num6 = 0
      this.nums.num7 = 0
      this.nums.num8 = 0
      const item = this.controller + this.method
      this.$localStorage.set(item, JSON.stringify(this.formInline))
      this.getFetch(this.getDataUrl, this.formInline).then(response => {
        this.data = response.data
        for (let i = 0; i < this.data.length; i++) {
          this.data[i].num1 = 0
          this.data[i].num2 = 0
          this.data[i].num3 = 0
          this.data[i].num4 = 0
          this.data[i].num5 = 0
          this.data[i].num6 = 0
          this.data[i].num7 = 0
          this.data[i].num8 = 0
          for (let j = 0; j < this.data[i].lessons.length; j++) {
            if (this.data[i].lessons[j].lesson_id === 30) {
              // 口算
              this.data[i].num1 += this.data[i].lessons[j].num * this.data[i].lessons[j].conf
            } else if (this.data[i].lessons[j].lesson_id === 27) {
              // 畅游书海
              this.data[i].num2 += this.data[i].lessons[j].num * this.data[i].lessons[j].conf
            } else if (this.data[i].lessons[j].lesson_id === 29) {
              // 午读
              this.data[i].num3 += this.data[i].lessons[j].num * this.data[i].lessons[j].conf
            } else if (this.data[i].lessons[j].lesson_id === 28) {
              // 早读
              this.data[i].num4 += this.data[i].lessons[j].num * this.data[i].lessons[j].conf
            } else if (this.data[i].lessons[j].lesson_id === 37) {
              // 早操
              this.data[i].num6 += this.data[i].lessons[j].num * this.data[i].lessons[j].conf
            } else if (this.data[i].lessons[j].lesson_id === 38) {
              // 课间操
              this.data[i].num7 += this.data[i].lessons[j].num * this.data[i].lessons[j].conf
            } else if (this.data[i].lessons[j].lesson_id === 40) {
              // 特色课
              this.data[i].num8 += this.data[i].lessons[j].num * this.data[i].lessons[j].conf
            } else {
              // 主课
              this.data[i].num5 += this.data[i].lessons[j].num * this.data[i].lessons[j].conf
            }
          }
          this.data[i].num1 = Math.floor(this.data[i].num1.toFixed(2) * 100) / 100
          this.data[i].num2 = Math.floor(this.data[i].num2.toFixed(2) * 100) / 100
          this.data[i].num3 = Math.floor(this.data[i].num3.toFixed(2) * 100) / 100
          this.data[i].num4 = Math.floor(this.data[i].num4.toFixed(2) * 100) / 100
          this.data[i].num5 = Math.floor(this.data[i].num5.toFixed(2) * 100) / 100
          this.data[i].num6 = Math.floor(this.data[i].num6.toFixed(2) * 100) / 100
          this.data[i].num7 = Math.floor(this.data[i].num7.toFixed(2) * 100) / 100
          this.data[i].num8 = Math.floor(this.data[i].num8.toFixed(2) * 100) / 100
          this.nums.num1 += this.data[i].num1
          this.nums.num2 += this.data[i].num2
          this.nums.num3 += this.data[i].num3
          this.nums.num4 += this.data[i].num4
          this.nums.num5 += this.data[i].num5
          this.nums.num6 += this.data[i].num6
          this.nums.num7 += this.data[i].num7
          this.nums.num8 += this.data[i].num8
        }
        this.loading = false
      })
    },
    clearData () {
      const o = this.$route.path.split('/')
      this.controller = (o[1] ? o[1] : undefined)
      this.method = (o[2] ? o[2] : undefined)
      this.addPath = {}
      this.formInline = {
        limit: 10,
        page: 1
      }
      this.loading = true
      this.filters = []
      this.tableItems = []
      this.pageTotal = 0
      this.data = []
      this.buttonItems = []
    },
    async install () {
      this.clearData()
      let props = this.$route.matched[0].props
      this.lessons = await this.$store.dispatch('getOption', 'lesson')
      if (!this.$isEmpty(props)) {
        // 初始化
        this.changeUrl = '/api/' + this.controller + '/edit'
        this.getDataUrl = '/api/' + this.controller + '/index'
        props = props.default
        if (!await this.permissionCheck(props.permission)) {
          this.$message.error(this.$t('error.1'))
        } else {
          let formValue = this.$localStorage.get(this.controller + this.method)
          if (formValue) {
            formValue = JSON.parse(formValue)
          }
          if (formValue && formValue.limit) this.formInline.limit = 100
          if (formValue && formValue.page) this.formInline.page = formValue.page
          for (let prop in props) {
            switch (prop) {
              case 'addPath':
                if (props.addPath && ((props.addPath.display && await this.permissionCheck(props.addPath.display)) || !props.addPath.display)) {
                  const addPath = { ...props.addPath }
                  if (!addPath.url) {
                    addPath.url = '/' + this.controller + '/add'
                  }
                  if (!addPath.text) {
                    addPath.text = 'normal.add'
                  }
                  this.addPath = addPath
                }
                break
              case 'addButton':
                if (props.addButton) {
                  const addButton = []
                  for (let i = 0; i < props.addButton.length; i++) {
                    if ((props.addButton[i].display && await this.permissionCheck(props.addButton[i].display)) || !props.addButton[i].display) {
                      addButton.push(props.addButton[i])
                    }
                  }
                  this.addButton = addButton
                }
                break
              case 'filters':
                for (let item in props.filters) {
                  if (typeof props.filters[item].display === 'object' && (!await this.permissionCheck(props.filters[item].display))) continue
                  if (props.filters[item].option) {
                    if (typeof props.filters[item].option === 'string') {
                      props.filters[item].option = await this.$store.dispatch('getOption', props.filters[item].option)
                    }
                    const option = [...props.filters[item].option]
                    if (props.filters[item].multiple) {
                      this.$set(this.formInline, props.filters[item].value, [])
                    } else if (props.filters[item].filterable) {
                      this.$set(this.formInline, props.filters[item].value, props.filters[item].default)
                    } else {
                      option.unshift({ label: this.$t('normal.all') })
                      this.$set(this.formInline, props.filters[item].value, props.filters[item].default)
                    }
                    if (!props.filters[item].filterable) {
                      props.filters[item].options = option
                    } else {
                      props.filters[item].options.splice(0, props.filters[item].options.length)
                    }
                  }
                  // 从localstroge取出数据
                  if (formValue && formValue[props.filters[item].value]) {
                    if (props.filters[item].filterable) {
                      if (formValue[props.filters[item].value]) {
                        const r = await this.getFetch('/api/user/getSomeOne', { value: formValue[props.filters[item].value] })
                        props.filters[item].options.splice(0, props.filters[item].options.length)
                        props.filters[item].options.push({ label: '全部' })
                        for (let i = 0; i < r.length; i++) {
                          props.filters[item].options.push(r[i])
                        }
                      }
                    }
                    // 如果未hidden则不取出缓存数据
                    if (props.filters[item].type !== 'hidden') {
                      this.$set(this.formInline, props.filters[item].value, formValue[props.filters[item].value])
                    }
                  }
                  if (this.$route.query[props.filters[item].value]) {
                    if (isNaN(parseInt(this.$route.query[props.filters[item].value]))) {
                      this.$set(this.formInline, props.filters[item].value, this.$route.query[props.filters[item].value])
                    } else {
                      this.$set(this.formInline, props.filters[item].value, parseInt(this.$route.query[props.filters[item].value]))
                    }
                  }
                  this.filters.push(props.filters[item])
                }
                break
              case 'tableItems':
                for (let item in props.tableItems) {
                  if (typeof props.tableItems[item].display === 'object' && (!await this.permissionCheck(props.tableItems[item].display))) continue
                  if (typeof props.tableItems[item].rewrite === 'object') {
                    props.tableItems[item].rewrite = await this.permissionCheck(props.tableItems[item].rewrite)
                  }
                  if (props.tableItems[item].option && typeof props.tableItems[item].option === 'string') {
                    props.tableItems[item].option = await this.$store.dispatch('getOption', props.tableItems[item].option)
                  }
                  this.tableItems.push(props.tableItems[item])
                }
                break
              case 'buttonItems':
                for (let item in props.buttonItems) {
                  if (typeof props.buttonItems[item].display === 'object' && (!await this.permissionCheck(props.buttonItems[item].display))) continue
                  this.buttonItems.push(props.buttonItems[item])
                }
                break
              case 'buttonWidth':
                this.buttonWidth = props.buttonWidth
                break
              case 'changeUrl':
                this.changeUrl = props.changeUrl
                break
              case 'getDataUrl':
                this.getDataUrl = '/api/' + props.getDataUrl
            }
          }
          if (!props.addButton) {
            this.addButton = []
          }
          if (!props.buttonWidth) {
            this.buttonWidth = res => {
              return 230
            }
          }
          this.getData()
        }
      }
    }
  },
  watch: {
    '$route' (to, from) {
      this.install()
    }
  },
  created () {
    this.install()
  }
}
</script>
