<template>
  <div>
    <vue-qr :text="href + tempId"></vue-qr>
    <div>{{href + tempId}}</div>
  </div>
</template>
<script>
import VueQr from 'vue-qr'
export default {
  components: {
    VueQr
  },
  data () {
    return {
      href: 'https://wechat.widenteen.com/chart?chart_id=',
      tempId: 0
    }
  },
  methods: {
  },
  created () {
    this.tempId = this.$route.query.id
  }
}
</script>
