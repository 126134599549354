<template>
  <div>
    <el-steps :active="active" finish-status="success">
      <el-step title="步骤 1"></el-step>
      <el-step title="步骤 2"></el-step>
      <el-step title="步骤 3"></el-step>
    </el-steps>
    <el-form :model="formItem" ref="formItem" label-width="100px" v-show="active === 1">
      <el-form-item :label="$t('examination.upDiff')">
        <el-rate v-model="formItem.up_diff"></el-rate>
      </el-form-item>
      <el-form-item :label="$t('examination.downDiff')">
        <el-rate v-model="formItem.down_diff"></el-rate>
      </el-form-item>
      <el-form-item :label="$t('knowledge.index')">
        <el-select v-model="formItem.knowledge_id[index]" v-for="(filter, index) in formItem.knowledge_id" :key="index" @change="knowledge('main', index)">
          <el-option
            v-for="item in status.knowledge"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <div style="font-size: 29px; width: 29px;" @click="addKnowledge('main')">+</div>
      </el-form-item>
      <el-form-item style="margin-top: 10px;">
        <el-button type="primary" @click="getQuestion()" plain>下一步</el-button>
      </el-form-item>
    </el-form>
    <div v-show="active === 2">
      <el-card shadow="hover" v-for="question in questions" :key="question.question_id">
        <span><el-checkbox v-model="formItem.questionList[question.question_id]"></el-checkbox></span><span><div v-html="question.title"></div></span>
        <div v-for="item in question.items" :key="item.item_id">
          <p v-html="item.item_content"></p>
          <p>分数：{{item.score}}&nbsp;&nbsp;&nbsp;&nbsp;难度：{{item.diff}}</p>
          <el-popover
            placement="right"
            trigger="click">
            <div v-html="item.content"></div>
            <el-button size="mini" slot="reference">查看解析</el-button>
          </el-popover>
          <el-popover
            placement="right"
            trigger="click">
            <div v-html="item.answer"></div>
            <el-button size="mini" slot="reference">查看答案</el-button>
          </el-popover>
        </div>
      </el-card>
      <el-button type="primary" @click="active = 1" plain>上一步</el-button>
      <el-button type="primary" @click="active = 3" plain>下一步</el-button>
    </div>
    <el-form :model="formItem" ref="formItem" label-width="100px" v-show="active === 3">
      <el-form-item :label="$t('examination.upDiff')">
        <el-rate v-model="formItem.up_diff" disabled></el-rate>
      </el-form-item>
      <el-form-item :label="$t('examination.downDiff')">
        <el-rate v-model="formItem.down_diff" disabled></el-rate>
      </el-form-item>
      <el-form-item :label="$t('knowledge.index')">
        <el-select disabled v-model="formItem.knowledge_id[index]" v-for="(filter, index) in formItem.knowledge_id" :key="index" @change="knowledge('main', index)">
          <el-option
            v-for="item in status.knowledge"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="margin-top: 10px;">
        <el-button type="primary" @click="active = 2" plain>上一步</el-button>
        <el-button type="primary" @click="onSubmit()" plain>提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
import { ueditorPaper } from '@/plugins/pluginsConfig'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  data () {
    return {
      formItem: {
        id: 0,
        up_diff: 1,
        down_diff: 5,
        knowledge_id: [0],
        questionList: {}
      },
      active: 1,
      status: {
        knowledge: []
      },
      myConfig: ueditorPaper,
      questions: []
    }
  },
  methods: {
    addKnowledge (w, i) {
      if (w === 'main') {
        if (this.formItem.knowledge_id.indexOf(0) === -1) {
          if (this.status.knowledge.length > 0) {
            this.formItem.knowledge_id.push(this.status.knowledge[0].value)
          } else {
            this.formItem.knowledge_id.push(0)
          }
        }
      }
    },
    knowledge (w, index, subIndex) {
      if (w === 'main') {
        const knowledgeId = this.formItem.knowledge_id[index]
        const tmp = []
        for (let i = 0; i < this.formItem.knowledge_id.length; i++) {
          if (i === index) continue
          if (this.formItem.knowledge_id[i] === knowledgeId) {
            tmp.push(i)
          }
        }
        for (let i = 0; i < tmp.length; i++) {
          this.formItem.knowledge_id.splice(tmp[i], 1)
        }
      } else if (w === 'sub') {
        const knowledgeId = this.formItem.item[index].knowledge_id[subIndex]
        const tmp = []
        for (let i = 0; i < this.formItem.item[index].knowledge_id.length; i++) {
          if (i === subIndex) continue
          if (this.formItem.item[index].knowledge_id[i] === knowledgeId) {
            tmp.push(i)
          }
        }
        for (let i = 0; i < tmp.length; i++) {
          this.formItem.knowledge_id.splice(tmp[i], 1)
        }
      }
    },
    getQuestion () {
      this.postFetch('/api/examination/check', this.formItem).then((response) => {
        this.questions = response
        this.active = 2
      })
    },
    onSubmit () {
      const formItem = {
        id: this.formItem.id,
        up_diff: this.formItem.up_diff,
        down_diff: this.formItem.down_diff,
        questionList: [],
        knowledge_id: this.formItem.knowledge_id
      }
      for (let item in this.formItem.questionList) {
        formItem.questionList.push(item)
      }
      this.$refs.formItem.validate((valid) => {
        if (valid) {
          this.postFetch('/api/examination_question/add', formItem).then((response) => {
            this.$router.go(-1)
          })
        } else {
          this.$message({
            message: this.$t('normal.validata'),
            type: 'error'
          })
        }
      })
    }
  },
  async created () {
    // eslint-disable-next-line
    uParse('.content', {
      'liiconpath': '/ueditor/themes/ueditor-list/'
    })
    this.formItem.id = this.$route.query.exam_item_id
    this.status.knowledge = await this.$store.dispatch('getOption', 'knowledge')
    if (this.status.knowledge.length > 0) this.formItem.knowledge_id[0] = this.status.knowledge[0].value
  }
}
</script>
