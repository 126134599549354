<template>
  <div>
    <el-form :model="formItem" ref="formItem" label-width="100px" v-show="active === 1">
      <el-form-item label="会认字">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="输入会认字"
          v-model="formItem.characterRead">
        </el-input>
        <div v-for="(city, index) in characterRead" :key="'r'+index" style="float: left;width: 300px;">
          <el-input style="width: 100px;" v-model="characterReadPinyin[index]" placeholder="请输入拼音">
            <template slot="prepend">{{characterRead[index]}}</template>
          </el-input>
        </div>
      </el-form-item>
      <el-form-item label="会写字">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="输入会写字，用逗号“,”隔开"
          v-model="formItem.characterWrite">
        </el-input>
        <div v-for="(city, index) in characterWrite" :key="'r'+index" style="float: left;width: 300px;">
          <el-input style="width: 100px;" v-model="characterWritePinyin[index]" placeholder="请输入拼音">
            <template slot="prepend">{{characterWrite[index]}}</template>
          </el-input>
        </div>
      </el-form-item>
      <el-form-item label="词语">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="输入词语，用逗号“,”隔开"
          v-model="formItem.word">
        </el-input>
        <div v-for="(city, index) in word" :key="'r'+index" style="float: left;width: 300px;">
          <el-input style="width: 280px;" v-model="wordPinyin[index]" placeholder="请输入拼音">
            <template slot="prepend">{{word[index]}}</template>
          </el-input>
        </div>
      </el-form-item>
      <el-form-item style="margin-top: 10px;">
        <el-button type="primary" @click="onSubmit()" plain>提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { status } from '@/plugins/permission'
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
import pinyin from 'pinyin'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  watch: {
    'formItem.characterRead': function (a, b, c) {
      if (!this.initRead) {
        this.characterRead = a.split('')
        for (let i = 0; i < this.characterRead.length; i++) {
          if (!(typeof this.characterReadPinyin[i] === 'string')) {
            this.characterReadPinyin[i] = pinyin(this.characterRead[i])[0]
          }
        }
      } else {
        this.initRead = false
      }
    },
    'formItem.characterWrite': function (a, b, c) {
      if (!this.initWrite) {
        this.characterWrite = a.split('')
        for (let i = 0; i < this.characterWrite.length; i++) {
          if (!(typeof this.characterWritePinyin[i] === 'string')) {
            this.characterWritePinyin[i] = pinyin(this.characterWrite[i])[0]
          }
        }
      } else {
        this.initWrite = false
      }
    },
    'formItem.word': function (a, b, c) {
      if (!this.initWord) {
        this.word = a.replace(/，/g, ',').split(',')
        for (let i = 0; i < this.word.length; i++) {
          if (!(typeof this.wordPinyin[i] === 'string')) {
            this.wordPinyin[i] = pinyin(this.word[i])
          }
        }
      } else {
        this.initWord = false
      }
    }
  },
  data () {
    return {
      initRead: true,
      initWrite: true,
      initWord: true,
      formItem: {
        characterRead: '',
        characterWrite: '',
        word: ''
      },
      characterRead: [],
      characterReadPinyin: [],
      characterWrite: [],
      characterWritePinyin: [],
      word: [],
      wordPinyin: [],
      active: 1,
      status: {
        exam_type: status.examType,
        knowledge: [],
        classes: []
      },
      questions: [],
      checkAll: false,
      isIndeterminate: false
    }
  },
  methods: {
    onSubmit () {
      this.$refs.formItem.validate((valid) => {
        if (valid) {
          this.postFetch('/api/chinese_text/editAll', {
            characterRead: this.characterRead.join(','),
            characterReadPinyin: this.characterReadPinyin.join(','),
            characterWrite: this.characterWrite.join(','),
            characterWritePinyin: this.characterWritePinyin.join(','),
            word: this.word.join(','),
            wordPinyin: this.wordPinyin.join('|'),
            id: this.$route.query.id
          }).then((response) => {
            this.$router.go(-1)
          })
        } else {
          this.$message({
            message: this.$t('normal.validata'),
            type: 'error'
          })
        }
      })
    }
  },
  async created () {
    const all = await this.getFetch('/api/chinese_text/getAll', {
      id: this.$route.query.id
    })
    const characterRead = []
    const characterReadPinyin = []
    const characterWrite = []
    const characterWritePinyin = []
    for (let i = 0; i < all.characters.length; i++) {
      if (all.characters[i].target === 1) {
        characterRead.push(all.characters[i].character)
        characterReadPinyin.push(all.characters[i].pinyin)
      } else if (all.characters[i].target === 2) {
        characterWrite.push(all.characters[i].character)
        characterWritePinyin.push(all.characters[i].pinyin)
      }
    }
    this.formItem.characterRead = characterRead.join('')
    this.formItem.characterWrite = characterWrite.join('')
    this.characterRead = characterRead
    this.characterWrite = characterWrite
    this.characterReadPinyin = characterReadPinyin
    this.characterWritePinyin = characterWritePinyin

    const word = []
    const wordPinyin = []
    for (let i = 0; i < all.words.length; i++) {
      word.push(all.words[i].word)
      wordPinyin.push(all.words[i].pinyin)
    }
    this.formItem.word = word.join(',')
    this.word = word
    this.wordPinyin = wordPinyin
  }
}
</script>
