<template>
  <div>
    <el-steps :active="active" finish-status="success">
      <el-step title="步骤 1"></el-step>
      <el-step title="步骤 2"></el-step>
      <el-step title="步骤 3"></el-step>
    </el-steps>
    <el-form :model="formItem" ref="formItem" label-width="100px" v-show="active === 1">
      <el-form-item :label="$t('knowledge.index')">
        <el-select v-model="formItem.text_id[index]" v-for="(filter, index) in formItem.text_id" :key="index" @change="chineseText('main', index)">
          <el-option
            v-for="item in status.chineseText"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <div style="font-size: 29px; width: 29px;" @click="addChineseText('main')">+</div>
      </el-form-item>
      <el-form-item style="margin-top: 10px;">
        <el-button type="primary" @click="getQuestion()" plain>下一步</el-button>
      </el-form-item>
    </el-form>
    <el-form :model="formItem" ref="formItem" label-width="100px" v-show="active === 2">
      <el-form-item label="会读字">
        <el-checkbox-group v-model="formItem.characterRead">
          <el-checkbox :checked="true" :label="item.character_link_id" v-for="item in characterRead" :key="item.character_link_id">{{item.character+item.pinyin}}</el-checkbox>
        </el-checkbox-group>
        <el-checkbox-group v-model="formItem.characterWrite">
          <el-checkbox :checked="true" :label="item.character_link_id" v-for="item in characterWrite" :key="item.character_link_id">{{item.character+item.pinyin}}</el-checkbox>
        </el-checkbox-group>
        <el-checkbox-group v-model="formItem.word">
          <el-checkbox :checked="true" :label="item.word_link_id" v-for="item in word" :key="item.word_link_id">{{item.word+item.pinyin}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-button type="primary" @click="active = 1" plain>上一步</el-button>
      <el-button type="primary" @click="active = 3" plain>下一步</el-button>
    </el-form>
    <el-form :model="formItem" ref="formItem" label-width="100px" v-show="active === 3">
      <el-form-item :label="$t('normal.class')">
        <el-select v-model="formItem.class_id">
          <el-option
            v-for="item in status.classes"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('knowledge.index')">
        <el-select disabled v-model="formItem.text_id[index]" v-for="(filter, index) in formItem.text_id" :key="index" @change="chineseText('main', index)">
          <el-option
            v-for="item in status.chineseText"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="系统自动出题数目">
        <el-input-number v-model="formItem.num" :min="0" label="最小为0"></el-input-number>
      </el-form-item>
      <el-form-item style="margin-top: 10px;">
        <el-button type="primary" @click="active = 2" plain>上一步</el-button>
        <el-button type="primary" @click="onSubmit()" plain>提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  data () {
    return {
      formItem: {
        text_id: [0],
        characterRead: [],
        characterWrite: [],
        word: [],
        class_id: 0,
        num: 3
      },
      characterRead: [],
      characterWrite: [],
      word: [],
      active: 1,
      status: {
        chineseText: [],
        classes: []
      }
    }
  },
  methods: {
    addChineseText (w, i) {
      if (w === 'main') {
        if (this.formItem.text_id.indexOf(0) === -1) {
          if (this.status.chineseText.length > 0) {
            this.formItem.text_id.push(this.status.chineseText[0].value)
          } else {
            this.formItem.text_id.push(0)
          }
        }
      }
    },
    chineseText (w, index, subIndex) {
      if (w === 'main') {
        const chineseTextId = this.formItem.text_id[index]
        const tmp = []
        for (let i = 0; i < this.formItem.text_id.length; i++) {
          if (i === index) continue
          if (this.formItem.text_id[i] === chineseTextId) {
            tmp.push(i)
          }
        }
        for (let i = 0; i < tmp.length; i++) {
          this.formItem.text_id.splice(tmp[i], 1)
        }
      } else if (w === 'sub') {
        const chineseTextId = this.formItem.item[index].text_id[subIndex]
        const tmp = []
        for (let i = 0; i < this.formItem.item[index].text_id.length; i++) {
          if (i === subIndex) continue
          if (this.formItem.item[index].text_id[i] === chineseTextId) {
            tmp.push(i)
          }
        }
        for (let i = 0; i < tmp.length; i++) {
          this.formItem.text_id.splice(tmp[i], 1)
        }
      }
    },
    getQuestion () {
      this.postFetch('/api/chinese_examination/check', this.formItem).then((response) => {
        this.characterRead = []
        this.characterWrite = []
        for (let i = 0; i < response.chineseCharacter.length; i++) {
          if (response.chineseCharacter[i].target === 1) {
            this.characterRead.push(response.chineseCharacter[i])
          } else if (response.chineseCharacter[i].target === 2) {
            this.characterWrite.push(response.chineseCharacter[i])
          }
        }
        this.word = response.chineseWord
        this.active = 2
      })
    },
    onSubmit () {
      this.$refs.formItem.validate((valid) => {
        if (valid) {
          this.postFetch('/api/chinese_examination/add', {
            characterRead: this.formItem.characterRead.join(','),
            characterWrite: this.formItem.characterWrite.join(','),
            word: this.formItem.word.join(','),
            text_id: this.formItem.text_id.join(','),
            class_id: this.formItem.class_id,
            num: this.formItem.num
          }).then((response) => {
            this.$router.go(-1)
          })
        } else {
          this.$message({
            message: this.$t('normal.validata'),
            type: 'error'
          })
        }
      })
    }
  },
  async created () {
    // eslint-disable-next-line
    uParse('.content', {
      'liiconpath': '/ueditor/themes/ueditor-list/'
    })
    this.status.chineseText = await this.$store.dispatch('getOption', 'chinese_text')
    console.log(this.status.chineseText)
    this.status.classes = await this.$store.dispatch('getOption', 'classes')
    if (this.status.chineseText.length > 0) this.formItem.text_id[0] = this.status.chineseText[0].value
  }
}
</script>
