<template>
  <div>
    <el-form label-width="100px">
      <el-date-picker
        v-model="formItem.date"
        type="date"
        value-format="yyyy-MM-dd"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="getData">
      </el-date-picker>
      <el-checkbox-group v-model="subDate" size="small">
        <el-tag v-for="item in data" :key="item.student_id">{{item.name + item.class_name + item.room_code}}</el-tag>
      </el-checkbox-group>
    </el-form>
  </div>
</template>
<script>
import { status } from '@/plugins/permission'
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'

export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  data () {
    return {
      setValue: [],
      weekNumber: 1,
      status: {
        weekDay: status.weekDay
      },
      subDate: [],
      formItem: {
        date: ''
      },
      data: []
    }
  },
  methods: {
    getData () {
      this.getFetch('/api/sleep/check', this.formItem).then(response => {
        this.data = response
      })
    }
  },
  created () {
    this.getData()
  }
}
</script>
