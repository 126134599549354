<template>
  <div>
    <el-button type="primary" size="small" @click.native.prevent="downloadCsv" plain>{{$t('button.download')}}</el-button>
    <el-form :inline="true" :model="formInline" @submit.native.prevent>
      <el-form-item label="时间">
        <el-date-picker
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="formInline.created_at"
          align="right"
          :picker-options="pickerOptions"
          type="datetimerange"
          range-separator="-"
          @change="onSubmit">
        </el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('chartTemplate.studentName')">
        <el-input v-model="formInline.name" @keyup.enter.native="onSubmit"></el-input>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column :label="$t('chartTemplate.studentName')">
        <template slot-scope="scope">
          <div>{{scope.row.studentName}}</div>
        </template>
      </el-table-column>
      <el-table-column v-for="(item, index) in template" :label="item.title" :key="index">
        <template slot-scope="scope">
          <div>{{scope.row['chart' + (index)]}}</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="layout-content-right">
      <el-pagination
        @current-change="changePage"
        :current-page="formInline.page"
        :page-size="formInline.limit"
        layout="total, prev, pager, next, jumper"
        :total="pageTotal">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  data () {
    return {
      template: [],
      loading: true,
      formInline: {
        temp_id: 0,
        name: '',
        created_at: '',
        limit: 10,
        page: 1
      },
      tableData: [],
      pageTotal: 0,
      data: [],
      buttonItems: [],
      isShowImageDialog: false,
      showImageList: []
    }
  },
  methods: {
    formalView (row, value) {
      value = value.split('|')
      let word = ''
      for (let i in value) {
        if (row[value[i]] || row[value[i]] === 0) {
          word += row[value[i]]
        }
      }
      return word
    },
    checkOption (value, option) {
      for (let i = 0; i < option.length; i++) {
        if (option[i].value === value) {
          return option[i].label
        }
      }
    },
    onSubmit () {
      this.formInline.page = 1
      this.getData()
    },
    changePage (page) {
      this.formInline.page = page
      this.getData()
    },
    downloadCsv () {
      this.getFetch('/api/chart_template/chart_detail_down', this.formInline).then((response) => {
        let a = document.createElement('a')
        let data = '\ufeff学生姓名,'
        const template = JSON.parse(response.template)
        for (let i = 0; i < template.length; i++) {
          data += template[i].title + ','
        }
        data += '\n'
        for (let i = 0; i < response.details.length; i++) {
          data += response.details[i].name + ','
          const detail = JSON.parse(response.details[i].chart_content)
          for (let j in detail) {
            data += detail[j].replace(/\n/g, ';') + ','
          }
          data += '\n'
        }
        const objectUrl = URL.createObjectURL(new Blob([data], { type: 'text/csv,charset=UTF-8' }))
        a.setAttribute('href', objectUrl)
        a.setAttribute('download', new Date().toDateString() + '.csv')
        a.click()
        a.remove()
      })
    },
    getData () {
      this.getFetch('/api/chart_template/chart_detail', this.formInline).then((response) => {
        this.template = JSON.parse(response.template)
        this.tableData = []
        for (let i = 0; i < response.details.length; i++) {
          const tmp = {
            studentName: response.details[i].name,
            ...JSON.parse(response.details[i].chart_content)
          }
          this.tableData.push(tmp)
        }
        this.pageTotal = Number(response.count)
        this.loading = false
      })
    }
  },
  created () {
    this.formInline.temp_id = this.$route.query.id
    this.getData()
  }
}
</script>
