const ueditorPaper = {
  UEDITOR_HOME_URL: '/ueditor/',
  serverUrl: '/api/upload/ueditor',
  pasteplain: true,
  emotionLocalization: true,
  saveInterval: 0,
  initialFrameWidth: 580,
  initialFrameHeight: 500,
  enableAutoSave: false,
  autoFloatEnabled: false,
  toolbars: [[
    'source', // 源代码
    'preview', // 预览
    'searchreplace', // 查询替换
    'removeformat', // 清除格式
    'cleardoc', // 清空文档
    '|',
    'undo', // 撤销
    'redo', // 重做
    '|',
    'forecolor', // 字体颜色
    'bold', // 加粗
    'indent', // 首行缩进
    'italic', // 斜体
    'underline', // 下划线
    'strikethrough', // 删除线
    'subscript', // 下标
    'fontborder', // 字符边框
    'superscript', // 上标
    'horizontal', // 分隔线
    'insertorderedlist', // 有序列表
    'insertunorderedlist', // 无序列表
    'lineheight' // 行间距
  ], [
    'formatmatch', // 格式刷
    'pasteplain', // 纯文本粘贴模式
    '|',
    'inserttable', // 插入表格
    'edittable', // 表格属性
    'insertrow', // 前插入行
    'insertcol', // 前插入列
    'deleterow', // 删除行
    'deletecol', // 删除列
    'mergecells', // 合并多个单元格
    'splittocells', // 完全拆分单元格
    'deletecaption', // 删除表格标题
    'inserttitle', // 插入标题
    'deletetable', // 删除表格
    '|',
    'emotion', // 表情
    'spechars', // 特殊字符
    'simpleupload', // 单图上传
    'scrawl', // 涂鸦
    'fullscreen' // 全屏
  ]]
}

const ueditorNormal = {
  UEDITOR_HOME_URL: '/ueditor/',
  serverUrl: '/api/upload/ueditor',
  pasteplain: true,
  emotionLocalization: true,
  saveInterval: 0,
  initialFrameWidth: 580,
  initialFrameHeight: 500,
  enableAutoSave: false,
  autoFloatEnabled: false,
  toolbars: [[
    'source', // 源代码
    'preview', // 预览
    'searchreplace', // 查询替换
    'removeformat', // 清除格式
    'cleardoc', // 清空文档
    '|',
    'undo', // 撤销
    'redo', // 重做
    '|',
    'forecolor', // 字体颜色
    'bold', // 加粗
    'indent', // 首行缩进
    'italic', // 斜体
    'underline', // 下划线
    'strikethrough', // 删除线
    'subscript', // 下标
    'fontborder', // 字符边框
    'superscript', // 上标
    'horizontal', // 分隔线
    'insertorderedlist', // 有序列表
    'insertunorderedlist', // 无序列表
    'lineheight' // 行间距
  ], [
    'formatmatch', // 格式刷
    'pasteplain', // 纯文本粘贴模式
    '|',
    'inserttable', // 插入表格
    'edittable', // 表格属性
    'insertrow', // 前插入行
    'insertcol', // 前插入列
    'deleterow', // 删除行
    'deletecol', // 删除列
    'mergecells', // 合并多个单元格
    'splittocells', // 完全拆分单元格
    'deletecaption', // 删除表格标题
    'inserttitle', // 插入标题
    'deletetable', // 删除表格
    '|',
    'justifyleft', // 居左对齐
    'justifyright', // 居右对齐
    'justifycenter', // 居中对齐
    'justifyjustify', // 两端对齐
    '|',
    'emotion', // 表情
    'spechars', // 特殊字符
    'simpleupload', // 单图上传
    'scrawl', // 涂鸦
    'fullscreen' // 全屏
  ]]
}

export { ueditorPaper, ueditorNormal }
