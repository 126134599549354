<template>
  <div>
    <el-form :model="formItem" ref="formItem" label-width="100px">
      <vue-qr :text="href + crypted"></vue-qr>
      {{href + crypted}}
      <el-form-item v-if="name" :label="$t('normal.name')">
        <div>{{name}}</div>
      </el-form-item>
      <el-form-item :label="$t('normal.desc')">
        <vue-ueditor-wrap v-model="formItem.desc" :config="myConfig" style="width: 100%"></vue-ueditor-wrap>
      </el-form-item>
      <el-form-item style="margin-top: 10px;">
        <el-button type="primary" @click="onSubmit('formItem')" plain>提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { status } from '@/plugins/permission'
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
import VueUeditorWrap from 'vue-ueditor-wrap'
import { ueditorNormal } from '@/plugins/pluginsConfig'
import VueQr from 'vue-qr'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  components: {
    VueUeditorWrap,
    VueQr
  },
  data () {
    return {
      href: 'https://wechat.widenteen.com/word?w=',
      formItem: {
        student_id: 0,
        desc: ''
      },
      name: '',
      status: {
        grade: status.grade,
        questionType: [],
        questionFrom: status.questionFrom
      },
      myConfig: ueditorNormal,
      crypted: ''
    }
  },
  methods: {
    onSubmit () {
      this.$refs.formItem.validate((valid) => {
        if (valid) {
          this.$msgbox({
            title: this.$t('normal.tip'),
            message: this.$t('normal.tipDes'),
            confirmButtonText: this.$t('button.confirm'),
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true
                instance.confirmButtonText = this.$t('normal.process')
                instance.title = this.$t('normal.processTitle')
                instance.message = this.$t('normal.processMessage')
                setTimeout(() => {
                  done()
                  setTimeout(() => {
                    instance.confirmButtonLoading = false
                  }, 300)
                }, 1000)
              } else {
                done()
              }
            }
          }).then(action => {
            if (action === 'confirm') {
              this.postFetch('/api/student_rate/setValue', this.formItem, { needSuccess: true })
            }
          })
        } else {
          this.$message({
            message: this.$t('normal.validata'),
            type: 'error'
          })
        }
      })
    }
  },
  async created () {
    // eslint-disable-next-line
    uParse('.content', {
      'liiconpath': '/ueditor/themes/ueditor-list/'
    })
    this.formItem.student_id = this.$route.query.student_id
    this.getFetch('/api/student_rate/getValue', { student_id: this.formItem.student_id }).then((response) => {
      this.formItem.desc = response.desc
      this.name = response.name
      this.crypted = response.crypted
    })
  }
}
</script>
