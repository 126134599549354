<template>
  <div>
    <el-form label-width="100px">
      <el-form-item :label="$t('course.weekNumber')">
        <el-input-number v-model="weekNumber" :min="1" :max="30" :label="$t('course.weekNumberDesc')"></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('course.createRange')">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          @change="setRange">
        </el-date-picker>
      </el-form-item>
      <el-form-item :label="(item.date.getMonth() + 1) + '月' + item.date.getDate() + '日'" v-for="(item,index) in setValue" :key="index">
        <el-select v-model="setValue[index].value">
          <el-option
            v-for="item in status.weekDay"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="setValue[index].lesson" multiple placeholder="请选择" style="width: 550px;">
          <el-option
            v-for="item in courseInfo"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="margin-top: 10px;">
        <el-button type="primary" @click="onSubmit" plain>提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { status } from '@/plugins/permission'
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'

export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '下周',
          onClick (picker) {
            const start = new Date()
            let subTime = start.getDay()
            if (subTime === 0) subTime = 7
            subTime = (8 - subTime) * 3600 * 1000 * 24
            start.setTime(start.getTime() + subTime)
            const end = new Date(start.getTime() + 6 * 3600 * 1000 * 24)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      dateRange: '',
      setValue: [],
      weekNumber: 1,
      status: {
        weekDay: status.weekDay
      },
      courseInfo: [],
      courseList: []
    }
  },
  async created () {
    const r = await this.$store.dispatch('getOption', 'course_info')
    for (let i = 0; i < r.length; i++) {
      this.courseList.push(r[i].value)
    }
    this.courseInfo = r
  },
  methods: {
    setRange () {
      const range = (new Date(this.dateRange[1]).getTime() - new Date(this.dateRange[0]).getTime()) / 1000 / 3600 / 24
      if (range > 14) {
        this.$message.error('请选定少于15天的时间')
        return false
      }
      this.setValue = []
      for (let i = 0; i <= range; i++) {
        const dateValue = new Date(new Date(this.dateRange[0]).getTime() + i * 3600 * 1000 * 24)
        this.setValue.push({
          date: dateValue,
          value: dateValue.getDay(),
          lesson: [...this.courseList]
        })
      }
    },
    onSubmit () {
      const newValue = []
      for (let i = 0; i < this.setValue.length; i++) {
        newValue.push({
          date: this.setValue[i].date.getTime(),
          value: this.setValue[i].value,
          lesson: this.setValue[i].lesson
        })
      }
      let message = this.$t('normal.tipDes')
      this.postFetch('/api/course/createCheck', { value: newValue }).then((response) => {
        if (response) {
          message = response + '继续操作则会覆盖'
        }
        this.$msgbox({
          title: this.$t('normal.tip'),
          message,
          confirmButtonText: this.$t('button.confirm'),
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.confirmButtonText = this.$t('normal.process')
              instance.title = this.$t('normal.processTitle')
              instance.message = this.$t('normal.processMessage')
              setTimeout(() => {
                done()
                setTimeout(() => {
                  instance.confirmButtonLoading = false
                }, 300)
              }, 1000)
            } else {
              done()
            }
          }
        }).then(action => {
          if (action === 'confirm') {
            this.postFetch('/api/course/create', { value: newValue, weekNumber: this.weekNumber }, { needSuccess: true }).then((response) => {
              // this.$router.replace({ path: '/course/index' })
            })
          }
        })
      })
    }
  }
}
</script>
