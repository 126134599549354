<template>
  <div>
    <div :caclu="num=0">{{student.name}}</div>
    <div style="margin-top: 50px;width: 100%;float:left;">
      <p style="font-size: 30px;">会认字</p>
      <div v-for="(characterRead, index) in student.character" :key="index">
        <div v-if="characterRead.target === 1" style="width: 100px;float:left;">
          <div style="margin-top: 1px;" :caclu="num++">
            <div>{{num}}<el-switch v-model="characterRead.is_done" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0"></el-switch></div>
            <div style="width: 50px;">
              <img style="width: 60px;height: 40px;" src="/ueditor/dialogs/emotion/images/x/x_0002.gif"/>
              <span style="font-size: 50px;" v-html="characterRead.character"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 50px;width: 100%;float:left;">
      <p style="font-size: 30px;">会写字</p>
      <div v-for="(characterWrite, index) in student.character" :key="index">
        <div v-if="characterWrite.target === 2" style="width: 100px;float:left;">
          <div style="margin-top: 1px;" :caclu="num++">
            <div>{{num}}<el-switch v-model="characterWrite.is_done" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0"></el-switch></div>
            <div style="width: 50px;">
              <span style="font-size: 18px;" v-html="characterWrite.pinyin"></span>
              <img style="width: 60px;height: 60px;" src="/ueditor/dialogs/emotion/images/x/x_0001.gif"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 50px;width: 704px;float:left;">
      <p style="font-size: 30px;">词语</p>
      <div v-for="(word, index) in student.word" :key="index">
        <div style="margin-top: 1px;float: left;width: 340px;" :caclu="num++">
          <div>{{num}}<el-switch v-model="word.is_done" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0"></el-switch></div>
          <div v-for="(c, index) in word.pinyin" :key="index" style="width: 71px;float: left;">
            <span style="font-size: 18px;width: 60px;display: block; text-align: center;" v-html="c"></span>
            <img style="width: 60px;height: 60px;" src="/ueditor/dialogs/emotion/images/x/x_0001.gif"/>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 50px;width: 100%;float:left;">
      <el-button type="primary" @click="onSubmit()" plain>提交</el-button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  data () {
    return {
      id: 0,
      student: {
        name: ''
      }
    }
  },
  methods: {
    checkOption (value, option) {
      for (let i = 0; i < option.length; i++) {
        if (Number(option[i].value) === Number(value)) {
          return option[i].label
        }
      }
    },
    onSubmit () {
      const formItem = {
        character: [],
        word: []
      }
      for (let j = 0; j < this.student.word.length; j++) {
        formItem.word.push({
          value: Number(this.student.word[j].is_done),
          label: this.student.word[j].exam_w_id
        })
      }
      for (let j = 0; j < this.student.character.length; j++) {
        formItem.character.push({
          value: Number(this.student.character[j].is_done),
          label: this.student.character[j].exam_c_id
        })
      }
      console.log(formItem)
      this.postFetch('/api/chinese_examination_item/inputScore', formItem).then((response) => {
        this.$router.go(-1)
      })
    }
  },
  async created () {
    const student = await this.getFetch('/api/chinese_examination_item/getOne', { id: this.$route.query.exam_item_id })
    for (let j = 0; j < student.word.length; j++) {
      student.word[j].word = student.word[j].word.split('')
      student.word[j].pinyin = student.word[j].pinyin.split(',')
      student.word[j].is_done = String(student.word[j].is_done)
    }
    for (let j = 0; j < student.character.length; j++) {
      student.character[j].is_done = String(student.character[j].is_done)
    }
    this.student = student
    console.log(student)
    this.id = this.$route.query.exam_item_id
  }
}
</script>
