<template>
  <div>
    <el-form :model="formItem" :rules="rules" ref="formItem" label-width="100px">
      <el-form-item :label="$t(filter.label)" v-for="(filter,index) in formItems" :key="index" :prop="filter.value">
        <el-select
          :remote-method="filter.remoteMethod"
          :filterable="filter.filterable"
          :remote="filter.filterable"
          :reserve-keyword="filter.filterable"
          v-model="formItem[filter.value]"
          :multiple="filter.multiple"
          v-if="filter.option"
          @change="buffValue">
          <el-option
            v-for="item in filter.option"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <quill-editor
          v-else-if="filter.type === 'editor'"
          v-model="formItem[filter.value]"
          ref="myQuillEditor"
          :options="editorOption"
          @change="buffValue">
        </quill-editor>
        <el-upload
          v-else-if="filter.type === 'image'"
          :data="{ name: filter.value }"
          action="/api/upload/index"
          list-type="picture-card"
          :file-list="filter.fileList"
          :multiple="true"
          :limit="filter.limit"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :on-exceed="toManayFile"
          :on-success="handleSuccess">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-date-picker
          v-else-if="filter.type === 'datetime'"
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="formItem[filter.value]"
          align="right"
          :picker-options="pickerOptions"
          type="datetime"
          @change="buffValue">
        </el-date-picker>
        <div v-else-if="filter.type === 'hidden'"></div>
        <el-input :type="filter.type" v-model="formItem[filter.value]" v-else @blur="buffValue"></el-input>
      </el-form-item>
      <div v-for="(pick,index) in tempContent" :key="'content' + index">
        {{$t('chartTemplate.table') + (index+1)}}
        <el-form-item :label="$t('chartTemplate.title')">
          <el-input v-model="tempContent[index].title"></el-input>
        </el-form-item>
        <el-form-item :label="$t('chartTemplate.dataType')">
          <el-select
            v-model="tempContent[index].dataType">
            <el-option
              v-for="item in status.dataType"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('chartTemplate.required')">
          <el-select
            v-model="tempContent[index].required">
            <el-option
              v-for="item in status.boolen"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('chartTemplate.default')">
          <el-input v-model="tempContent[index].default"></el-input>
        </el-form-item>
        <el-form-item :label="$t('chartTemplate.option')" v-if="tempContent[index].dataType === 'picker'">
          <el-tag
            :key="tag"
            v-for="tag in tempContent[index].option"
            closable
            :disable-transitions="false"
            @close="handleClose(tag, index)">
            {{tag}}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="tempContent[index].optionItem.inputVisible"
            v-model="tempContent[index].optionItem.inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm(index)"
            @blur="handleInputConfirm(index)"
          >
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInput(index)">{{$t('normal.add')}}</el-button>
        </el-form-item>
        <el-form-item :label="$t('button.delete')" v-if="tempContent.length > 1">
          <el-button class="button-new-tag" size="small" @click="deleteContent(index)">{{$t('button.delete') + $t('chartTemplate.table')}}</el-button>
        </el-form-item>
      </div>
      <el-button class="button-new-tag" size="small" @click="addContent()">{{$t('normal.add') + $t('chartTemplate.table')}}</el-button>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('formItem')" plain>提交</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import { status } from '@/plugins/permission'
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
Quill.register('modules/ImageExtend', ImageExtend)
export default {
  mixins: [mixin(), propValue()],
  components: {
    quillEditor
  },
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  data () {
    const o = this.$route.path.split('/')
    return {
      status,
      tempContent: [{
        title: '',
        dataType: 'text',
        required: 1,
        defalut: '',
        option: [],
        optionItem: {
          inputVisible: false,
          inputValue: ''
        }
      }],
      controller: (o[1] ? o[1] : undefined),
      method: (o[2] ? o[2] : undefined),
      formItem: {
      },
      formItems: [],
      rules: {},
      dialogImageUrl: '',
      dialogVisible: false,
      loading: false,
      editorOption: {
        modules: {
          ImageExtend: {
            loading: true,
            name: 'file',
            action: '/api/upload/index',
            response: (res) => {
              return this.imgUrl + '/' + res.data.respData.key
            }
          },
          toolbar: {
            container: container,
            handlers: {
              'image': function () {
                QuillWatch.emit(this.quill.id)
              }
            }
          }
        }
      }
    }
  },
  methods: {
    addContent () {
      this.tempContent.push({
        title: '',
        dataType: 'text',
        required: 1,
        defalut: '',
        option: [],
        optionItem: {
          inputVisible: false,
          inputValue: ''
        }
      })
    },
    deleteContent (index) {
      if (this.tempContent.length > 1) {
        this.tempContent.splice(index, 1)
      }
    },
    handleClose (tag, index) {
      this.tempContent[index].option.splice(this.tempContent[index].option.indexOf(tag), 1)
    },
    handleInputConfirm (index) {
      let inputValue = this.tempContent[index].optionItem.inputValue
      if (inputValue) {
        this.tempContent[index].option.push(inputValue)
      }
      this.tempContent[index].optionItem.inputVisible = false
      this.tempContent[index].optionItem.inputValue = ''
    },
    showInput (index) {
      this.tempContent[index].optionItem.inputVisible = true
    },
    toManayFile () {
      this.$message({
        message: this.$t('normal.fileLimit')
      })
    },
    handleSuccess (file, fileList) {
      this.formItem[file.data.name].push(file.data.respData.key)
      this.buffValue()
    },
    handleRemove (file, fileList) {
      this.formItem[file.response.data.name] = []
      for (let fileItem in fileList) {
        this.formItem[file.response.data.name].push(fileList[fileItem].response.data.respData.key)
      }
      this.buffValue()
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    buffValue () {
      const item = this.controller + this.method
      this.$localStorage.set(item, JSON.stringify(this.formItem))
    },
    onSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$msgbox({
            title: this.$t('normal.tip'),
            message: this.$t('normal.tipDes'),
            confirmButtonText: this.$t('button.confirm'),
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true
                instance.confirmButtonText = this.$t('normal.process')
                instance.title = this.$t('normal.processTitle')
                instance.message = this.$t('normal.processMessage')
                setTimeout(() => {
                  done()
                  setTimeout(() => {
                    instance.confirmButtonLoading = false
                  }, 300)
                }, 1000)
              } else {
                done()
              }
            }
          }).then(action => {
            if (action === 'confirm') {
              const tempcontent = []
              for (let i = 0; i < this.tempContent.length; i++) {
                const temp = {
                  title: this.tempContent[i].title,
                  dataType: this.tempContent[i].dataType,
                  required: this.tempContent[i].required,
                  defalut: this.tempContent[i].defalut
                }
                if (this.tempContent[i].dataType === 'picker') {
                  temp.option = this.tempContent[i].option
                }
                tempcontent.push(temp)
              }
              this.postFetch('/api/' + this.controller + '/add', { ...this.formItem, temp_content: tempcontent }, { needSuccess: true }).then((response) => {
                this.$router.replace({ path: '/' + this.controller + '/index', query: this.$route.query })
              })
            }
          })
        } else {
          this.$message({
            message: this.$t('normal.validata'),
            type: 'error'
          })
        }
      })
    }
  },
  async created () {
    let props = this.$route.matched[0].props
    if (!this.$isEmpty(props)) {
      props = props.default
      if (!this.permissionCheck(props.permission)) {
        this.$message.error(this.$t('error.1'))
      } else {
        const rules = []
        for (let prop in props) {
          switch (prop) {
            case 'formItems':
              const item = this.controller + this.method
              let formValue = this.$localStorage.get(item)
              if (formValue) {
                formValue = JSON.parse(formValue)
              }
              for (let item in props.formItems) {
                if (typeof props.formItems[item].display === 'object' && (!this.permissionCheck(props.formItems[item].display))) continue
                if (props.formItems[item].multiple) {
                  if (props.formItems[item].default) {
                    this.$set(this.formItem, props.formItems[item].value, props.formItems[item].default)
                  } else {
                    this.$set(this.formItem, props.formItems[item].value, [])
                  }
                } else if (props.formItems[item].option) {
                  this.$set(this.formItem, props.formItems[item].value, props.formItems[item].default)
                  if (typeof props.formItems[item].option === 'string') {
                    props.formItems[item].option = await this.$store.dispatch('getOption', props.formItems[item].option)
                  }
                } else if (props.formItems[item].type === 'image') {
                  if (formValue && formValue[props.formItems[item].value] !== undefined) {
                    const fileList = []
                    for (let i in formValue[props.formItems[item].value]) {
                      fileList.push({
                        url: this.imgUrl + '/' + formValue[props.formItems[item].value][i]
                      })
                    }
                    props.formItems[item].fileList = fileList
                    this.$set(this.formItem, props.formItems[item].value, formValue[props.formItems[item].value])
                  } else {
                    this.$set(this.formItem, props.formItems[item].value, [])
                    props.formItems[item].fileList = []
                  }
                } else {
                  this.$set(this.formItem, props.formItems[item].value, props.formItems[item].default)
                }
                // 从localstroge取出数据
                if (formValue && formValue[props.formItems[item].value] !== undefined && props.formItems[item].type !== 'image') {
                  if (props.formItems[item].filterable) {
                    if (formValue[props.formItems[item].value]) {
                      const r = await this.getFetch(props.formItems[item].url, { value: formValue[props.formItems[item].value] })
                      props.formItems[item].option.splice(0, props.formItems[item].option.length)
                      for (let i = 0; i < r.length; i++) {
                        props.formItems[item].option.push(r[i])
                      }
                    }
                  }
                  // 如果未hidden则不取出缓存数据
                  if (props.formItems[item].type !== 'hidden') {
                    this.$set(this.formItem, props.formItems[item].value, formValue[props.formItems[item].value])
                  }
                }
                if (this.$route.query[props.formItems[item].value]) {
                  if (isNaN(parseInt(this.$route.query[props.formItems[item].value]))) {
                    this.$set(this.formItem, props.formItems[item].value, this.$route.query[props.formItems[item].value])
                  } else {
                    this.$set(this.formItem, props.formItems[item].value, parseInt(this.$route.query[props.formItems[item].value]))
                  }
                }
                this.formItems.push(props.formItems[item])
                if (props.formItems[item].rule) {
                  rules.push({ value: props.formItems[item].rule, labal: props.formItems[item].value })
                }
              }
              break
          }
        }
        this.rules = this.$validata.getRules(rules)
      }
    }
  }
}
</script>

<style>
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
</style>
