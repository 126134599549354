<template>
  <div>
    <div :caclu="num=0">{{item.name}}(总分：{{item.questions.score}})</div>
    <div v-if="item.questions.questions">
      <div v-if="item.questions.questions.add.length > 0">
        <p><b>改错练习</b></p>
        <div v-for="question in item.questions.questions.add" :key="question.question_id">
          <div :caclu="num++"></div>
          <div v-html="question.title"></div>
          <div v-for="(questionItem, index) in question.items" :key="questionItem.item_id">
            <span>{{num}}<span v-if="question.items.length > 1">.{{index + 1}}</span>：</span><span>分数({{questionItem.score}})</span><el-input-number v-model="score['a' + questionItem.item_id]" :min="0" :max="questionItem.score" label="分数"></el-input-number><span v-html="questionItem.item_content"></span>
          </div>
        </div>
      </div>
      <div v-for="(questionItem, index) in item.questions.questions.questionType" :key="index">
        <p><b>{{checkOption(index, status.questionType)}}</b></p>
        <div v-for="question in questionItem" :key="question.question_id">
          <div :caclu="num++"></div>
          <div v-html="question.title"></div>
          <div v-for="(questionItem, index) in question.items" :key="questionItem.item_id">
            <span>{{num}}<span v-if="question.items.length > 1">.{{index + 1}}</span>：</span><span>分数({{questionItem.score}})</span><el-input-number v-model="score['a' + questionItem.item_id]" :min="0" :max="questionItem.score" label="分数"></el-input-number><span v-html="questionItem.item_content"></span>
          </div>
        </div>
      </div>
      <el-button type="primary" @click="onSubmit()" plain>提交</el-button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  data () {
    return {
      id: 0,
      score: {},
      status: {
        questionFrom: [],
        questionType: []
      },
      te: 1,
      ca: 20,
      item: {
        name: '',
        questions: {
          score: 0
        }
      }
    }
  },
  methods: {
    checkOption (value, option) {
      for (let i = 0; i < option.length; i++) {
        if (Number(option[i].value) === Number(value)) {
          return option[i].label
        }
      }
    },
    onSubmit () {
      const formItem = {
        id: this.id,
        score: this.score
      }
      this.postFetch('/api/examination_item/inputScore', formItem).then((response) => {
        this.$router.go(-1)
      })
    }
  },
  async created () {
    this.status.questionFrom = status.questionFrom
    this.status.questionType = await this.$store.dispatch('getOption', 'question_type')
    this.item = await this.getFetch('/api/examination_item/getOne', { id: this.$route.query.exam_item_id })
    const questions = this.item.questions.questions
    const add = []
    const questionType = {}
    for (let item in questions) {
      for (let i = 0; i < questions[item].items.length; i++) {
        this.$set(this.score, 'a' + questions[item].items[i].item_id, questions[item].items[i].now_score)
      }
      // 系统出题单独列出
      if (questions[item].is_add) {
        add.push(questions[item])
        continue
      }
      // 其它题目进行分类
      if (!questionType[questions[item].question_type]) {
        questionType[questions[item].question_type] = []
      }
      questionType[questions[item].question_type].push(questions[item])
    }
    this.item.questions.questions = {
      add,
      questionType
    }
    this.id = this.$route.query.exam_item_id
  }
}
</script>
