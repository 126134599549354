<template>
  <div>
    <el-form label-width="100px">
      <el-checkbox-group v-model="subDate" size="small">
        <el-checkbox v-for="item in data" :key="item.user_id" :label="item.user_id" border :checked="item.check">{{item.name}}</el-checkbox>
      </el-checkbox-group>
      <el-form-item style="margin-top: 10px;">
        <el-button type="primary" @click="onSubmit" plain>提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { status } from '@/plugins/permission'
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'

export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  data () {
    return {
      setValue: [],
      weekNumber: 1,
      status: {
        weekDay: status.weekDay
      },
      subDate: [],
      formItem: {
        lesson_pay_id: 0
      },
      data: []
    }
  },
  methods: {
    onSubmit () {
      this.$msgbox({
        title: this.$t('normal.tip'),
        message: '确定吗？',
        confirmButtonText: this.$t('button.confirm'),
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = this.$t('normal.process')
            instance.title = this.$t('normal.processTitle')
            instance.message = this.$t('normal.processMessage')
            setTimeout(() => {
              done()
              setTimeout(() => {
                instance.confirmButtonLoading = false
              }, 300)
            }, 1000)
          } else {
            done()
          }
        }
      }).then(action => {
        if (action === 'confirm') {
          const subDate = {}
          for (let i = 0; i < this.subDate.length; i++) {
            subDate[i] = this.subDate[i]
          }
          this.postFetch('/api/lesson_pay/setUser', { userList: subDate, lesson_pay_id: this.formItem.lesson_pay_id }, { needSuccess: true }).then((response) => {
            // this.$router.replace({ path: '/course/index' })
          })
        }
      })
    }
  },
  created () {
    this.formItem.lesson_pay_id = this.$route.query.id
    this.getFetch('/api/lesson_pay/getUser', this.formItem).then(response => {
      this.data = response
    })
  }
}
</script>
