<template>
  <div>
    <el-collapse v-model="activeNames">
      <el-collapse-item :title="$t('normal.base')" name="1">
        <el-row>
          <el-col :span="8">{{$t('normal.teacher')}}：{{data.username}}</el-col>
          <el-col :span="8">{{$t('normal.state')}}：{{checkOption(data.state, status.orderList)}}</el-col>
          <el-col :span="8">{{$t('normal.name')}}：{{data.name}}</el-col>
          <el-col :span="8">{{$t('normal.gender')}}：{{checkOption(data.gender, status.gender)}}</el-col>
          <el-col :span="8">{{$t('normal.school')}}：{{data.school}}</el-col>
          <el-col :span="8">{{$t('normal.bothday')}}：{{data.bothday}}</el-col>
          <el-col :span="8">{{$t('orderList.father_name')}}：{{data.father_name}}</el-col>
          <el-col :span="8">{{$t('orderList.father_work')}}：{{data.father_work}}</el-col>
          <el-col :span="8">{{$t('orderList.father_tel')}}：{{data.father_tel}}</el-col>
          <el-col :span="8">{{$t('orderList.mother_name')}}：{{data.mother_name}}</el-col>
          <el-col :span="8">{{$t('orderList.mother_work')}}：{{data.mother_work}}</el-col>
          <el-col :span="8">{{$t('orderList.mother_tel')}}：{{data.mother_tel}}</el-col>
          <el-col :span="8">{{$t('normal.address')}}：{{data.family_address}}</el-col>
          <el-col :span="8">{{$t('normal.time')}}：{{data.created_at}}</el-col>
          <el-col :span="8">{{$t('orderList.nowLevel')}}：{{data.now_level}}</el-col>
          <el-col :span="8">{{$t('orderList.comeIn')}}：{{checkOption(data.come_in, status.orderListComeIn)}}</el-col>
        </el-row>
      </el-collapse-item>
      <el-collapse-item :title="$t('orderList.methodRecode')" name="6">
        <el-row :key="index" v-for="(item,index) in data.detail">
          <el-col :span="3">{{$t('normal.methodPeople')}}：{{item.username}}</el-col>
          <el-col :span="3">{{$t('normal.oldState')}}：{{checkOption(item.old_state, status.orderList)}}</el-col>
          <el-col :span="3">{{$t('normal.newState')}}：{{checkOption(item.new_state, status.orderList)}}</el-col>
          <el-col :span="6">{{$t('normal.time')}}：{{item.created_at}}</el-col>
          <el-col :span="9">{{$t('normal.content')}}：{{item.content}}</el-col>
        </el-row>
      </el-collapse-item>
      <el-collapse-item :title="$t('normal.operate')" name="8" v-if="!$isEmpty(buttonItems)">
        <el-row>
          <span v-for="(buttonItem,index) in buttonItems" :key="index">
            <el-button style="margin: 5px" type="success" v-if="buttonItem.showIf({...data, ...{userInfo}})" size="small" @click.native.prevent="buttonDone(data, buttonItem)" plain>{{$t(buttonItem.buttonName)}}</el-button>
          </span>
        </el-row>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
import zhStatus from '@/lang/zh_status'
import enStatus from '@/lang/en_status'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo',
      'lang'
    ])
  },
  data () {
    return {
      activeNames: ['1', '6', '8'],
      status: zhStatus,
      buttonItems: [],
      data: {}
    }
  },
  methods: {
    getData () {
      this.getFetch('/api/order_list/detail', { id: this.$route.query.id }).then(response => {
        this.data = response
      })
    },
    checkOption (value, option) {
      if (option) {
        for (let i = 0; i < option.length; i++) {
          if (option[i].value === value) {
            return option[i].label
          }
        }
      }
    },
    listArray (value) {
      if (typeof value === 'object') {
        let v = ''
        for (let i = 0; i < value.length; i++) {
          v += value[i] + ' '
        }
        return v
      }
      return value
    },
    checkBoolen (value) {
      if (value === 0) {
        return '否'
      } else {
        return '是'
      }
    },
    getUrl (path, row) {
      let word = /%\S*?%/.exec(path)
      if (word === null) {
        return path
      } else {
        const v = this.$validator.trim(word[0], '%')
        if (row[v]) {
          path = path.replace(/%\S*?%/, row[v])
        } else {
          path = path.replace(/%\S*?%/, '')
        }
        return this.getUrl(path, row)
      }
    },
    buttonDone (row, buttonItem) {
      if (buttonItem.type === 'ajax') {
        this.getFetch(this.getUrl(buttonItem.path, row), null, { needSuccess: true }).then(response => {
          this.getData()
        })
      } else if (buttonItem.type === 'route') {
        this.$router.push(this.getUrl(buttonItem.path, row))
      } else if (buttonItem.type === 'contentAjax') {
        this.$prompt(this.$t(buttonItem.inputTitle), this.$t('normal.tip'), {
          confirmButtonText: this.$t('button.confirm'),
          cancelButtonText: this.$t('button.cancel')
        }).then(({ value }) => {
          this.getFetch(this.getUrl(buttonItem.path, row) + '&content=' + value, null, { needSuccess: true }).then(response => {
            this.getData()
          })
        })
      }
    },
    async install () {
      let props = this.$route.matched[0].props
      if (!this.$isEmpty(props)) {
        props = props.default
        if (!await this.permissionCheck(props.permission)) {
          this.$message.error(this.$t('error.1'))
        } else {
          for (let prop in props) {
            switch (prop) {
              case 'buttonItems':
                for (let item in props.buttonItems) {
                  if (typeof props.buttonItems[item].display === 'object' && (!await this.permissionCheck(props.buttonItems[item].display))) continue
                  this.buttonItems.push(props.buttonItems[item])
                }
                break
            }
          }
        }
      }
    }
  },
  async created () {
    this.getData()
    switch (this.lang) {
      case 'zh':
        this.status = zhStatus
        break
      case 'en':
        this.status = enStatus
        break
    }
    await this.install()
  }
}
</script>

<style lang="css">
  .line-one{
    height: 22px;
  }
</style>
