<template>
  <div>
    <el-date-picker
      value-format="yyyy-MM-dd HH:mm:ss"
      v-model="formItem.date"
      :picker-options="pickerOptions"
      type="datetimerange"
      range-separator="-"
      @change="getData">
    </el-date-picker>
    <el-collapse v-model="activeNames">
      <el-collapse-item title="所有记录" name="1">
        <el-row>
          <el-col :span="24">
            <el-table :data="data" style="width: 100%">
              <el-table-column prop="username" label="老师"></el-table-column>
              <el-table-column prop="updated_at" label="最后更新时间"></el-table-column>
              <el-table-column prop="content" label="老师留下记录"></el-table-column>
              <el-table-column label="来源">
                <template slot-scope="scope">
                  {{checkOption(scope.row.come_in, status.orderListComeIn)}}
                </template>
              </el-table-column>
              <el-table-column label="姓名">
                <template slot-scope="scope">
                  {{scope.row.name+' '+scope.row.bothday+' '+checkOption(scope.row.gender, status.gender)}}
                </template>
              </el-table-column>
              <el-table-column label="家庭">
                <template slot-scope="scope">
                  {{scope.row.father_name+' '+scope.row.father_tel+' '+scope.row.father_work+' '+scope.row.mother_name+' '+scope.row.mother_tel+' '+scope.row.mother_work}}
                </template>
              </el-table-column>
              <el-table-column label="状态">
                <template slot-scope="scope">
                  {{checkOption(scope.row.state, status.orderList)}}
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
import zhStatus from '@/lang/zh_status'
import enStatus from '@/lang/en_status'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo',
      'lang'
    ])
  },
  data () {
    return {
      status: zhStatus,
      activeNames: ['1', '2'],
      formItem: {
        date: '',
        come_in: 0
      },
      data: [],
      orderListComeIn: [],
      lenghtOne: []
    }
  },
  methods: {
    checkOption (value, option) {
      if (option) {
        for (let i = 0; i < option.length; i++) {
          if (option[i].value === value) {
            return option[i].label
          }
        }
      }
    },
    getData () {
      this.getFetch('/api/order_list/checkList', this.formItem).then(response => {
        this.data = response
      })
    }
  },
  async created () {
    this.getData()
    switch (this.lang) {
      case 'zh':
        this.orderListComeIn = [ ...zhStatus.orderListComeIn ]
        break
      case 'en':
        this.orderListComeIn = [ ...enStatus.orderListComeIn ]
        break
    }
    this.orderListComeIn.unshift({
      label: '全部',
      value: 0
    })
    let props = this.$route.matched[0].props
    if (!this.$isEmpty(props)) {
      props = props.default
      if (!await this.permissionCheck(props.permission)) {
        this.$message.error(this.$t('error.1'))
      } else {
        for (let prop in props) {
          switch (prop) {
            case 'buttonItems':
              for (let item in props.buttonItems) {
                if (typeof props.buttonItems[item].display === 'object' && (!await this.permissionCheck(props.buttonItems[item].display))) continue
                this.buttonItems.push(props.buttonItems[item])
              }
              break
          }
        }
      }
    }
  }
}
</script>

<style lang="css">
  .line-one{
    height: 22px;
  }
</style>
