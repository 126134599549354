<template>
  <div>
    <line-chart :chart-data="datacollection"></line-chart>
    <button @click="fillData()">Randomize</button>
  </div>
</template>
<script>
import { status } from '@/plugins/permission'
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
export default {
  mixins: [mixin(), propValue(), reactiveProp],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  extends: Line,
  props: ['options'],
  data () {
    return {
      datacollection: null,
      formItem: {
        id: 0,
        title: '',
        question_type: 0,
        grade: 1,
        content: '',
        answer: '',
        diff: 3,
        question_from: '',
        question_to: '',
        is_objective: false,
        score: 0
      },
      status: {
        grade: status.grade,
        questionType: []
      }
    }
  },
  methods: {
    onSubmit () {
      this.$refs.formItem.validate((valid) => {
        if (valid) {
          this.$msgbox({
            title: this.$t('normal.tip'),
            message: this.$t('normal.tipDes'),
            confirmButtonText: this.$t('button.confirm'),
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true
                instance.confirmButtonText = this.$t('normal.process')
                instance.title = this.$t('normal.processTitle')
                instance.message = this.$t('normal.processMessage')
                setTimeout(() => {
                  done()
                  setTimeout(() => {
                    instance.confirmButtonLoading = false
                  }, 300)
                }, 1000)
              } else {
                done()
              }
            }
          }).then(action => {
            if (action === 'confirm') {
              this.postFetch('/api/question/edit', this.formItem, { needSuccess: true }).then((response) => {
                // this.$router.replace({ path: '/' + this.controller + '/index', query: this.$route.query })
              })
            }
          })
        } else {
          this.$message({
            message: this.$t('normal.validata'),
            type: 'error'
          })
        }
      })
    }
  },
  async created () {
    this.renderChart(this.chartData, this.options)
    // this.formItem.id = this.$route.query.id
    // this.getFetch('/api/question/getone', { id: this.formItem.id }).then((response) => {
    // })
  }
}
</script>
