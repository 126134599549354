<template>
  <div>
    <el-form :model="formItem" ref="formItem" label-width="100px">
      <el-form-item :label="$t('normal.grade')">
        <el-select v-model="formItem.grade">
          <el-option
            v-for="item in status.grade"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('question.questionType')">
        <el-select v-model="formItem.question_type">
          <el-option
            v-for="item in status.questionType"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('question.questionFrom')">
        <el-select v-model="formItem.question_from">
          <el-option
            v-for="item in status.questionFrom"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('question.questionTo')">
        <el-input v-model="formItem.question_to"></el-input>
      </el-form-item>
      <el-form-item :label="$t('question.questionBody')">
        <vue-ueditor-wrap v-model="formItem.title" :config="myConfig" style="width: 100%"></vue-ueditor-wrap>
      </el-form-item>
      <el-form-item style="margin-top: 10px;">
        <el-button type="primary" @click="onSubmit('formItem')" plain>提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { status } from '@/plugins/permission'
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
import VueUeditorWrap from 'vue-ueditor-wrap'
import { ueditorPaper } from '@/plugins/pluginsConfig'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  components: {
    VueUeditorWrap
  },
  data () {
    return {
      formItem: {
        id: 0,
        title: '',
        question_type: 0,
        grade: 1,
        question_from: 1,
        question_to: ''
      },
      status: {
        grade: status.grade,
        questionType: [],
        questionFrom: status.questionFrom
      },
      myConfig: ueditorPaper
    }
  },
  methods: {
    onSubmit () {
      this.$refs.formItem.validate((valid) => {
        if (valid) {
          this.$msgbox({
            title: this.$t('normal.tip'),
            message: this.$t('normal.tipDes'),
            confirmButtonText: this.$t('button.confirm'),
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true
                instance.confirmButtonText = this.$t('normal.process')
                instance.title = this.$t('normal.processTitle')
                instance.message = this.$t('normal.processMessage')
                setTimeout(() => {
                  done()
                  setTimeout(() => {
                    instance.confirmButtonLoading = false
                  }, 300)
                }, 1000)
              } else {
                done()
              }
            }
          }).then(action => {
            if (action === 'confirm') {
              this.postFetch('/api/question/edit', this.formItem, { needSuccess: true }).then((response) => {
                this.$router.replace({ path: '/question/index' })
              })
            }
          })
        } else {
          this.$message({
            message: this.$t('normal.validata'),
            type: 'error'
          })
        }
      })
    }
  },
  async created () {
    // eslint-disable-next-line
    uParse('.content', {
      'liiconpath': '/ueditor/themes/ueditor-list/'
    })
    this.formItem.id = this.$route.query.id
    this.getFetch('/api/question/getone', { id: this.formItem.id }).then((response) => {
      this.formItem.content = response.content
      this.formItem.grade = response.grade
      this.formItem.question_type = response.question_type
      this.formItem.title = response.title
      this.formItem.diff = response.diff
      this.formItem.question_from = response.question_from
      this.formItem.question_to = response.question_to
      this.formItem.answer = response.answer
      this.formItem.is_objective = response.is_objective
      this.formItem.score = response.score
      this.formItem.is_item = response.is_item
    })
    this.status.questionType = await this.$store.dispatch('getOption', 'question_type')
    if (this.status.questionType.length > 0) this.formItem.question_type = this.status.questionType[0].value
  }
}
</script>
