<template>
  <div>
    <el-form :model="formItem" ref="formItem" label-width="100px">
      <el-form-item :label="$t('normal.answer')">
        <el-input v-model="formItem.answer"></el-input>
      </el-form-item>
      <el-form-item :label="$t('normal.diff')">
        <el-rate v-model="formItem.diff"></el-rate>
      </el-form-item>
      <el-form-item :label="$t('question.parse')">
        <vue-ueditor-wrap v-model="formItem.content" :config="myConfig" style="width: 100%"></vue-ueditor-wrap>
      </el-form-item>
      <el-form-item :label="$t('question.questionBody')">
        <vue-ueditor-wrap v-model="formItem.item_content" :config="myConfig" style="width: 100%"></vue-ueditor-wrap>
      </el-form-item>
      <el-form-item :label="$t('normal.score')" v-show="!formItem.is_item">
        <el-input-number v-model="formItem.score" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('knowledge.index')">
        <el-select v-model="formItem.knowledge_id[index]" v-for="(filter, index) in formItem.knowledge_id" :key="index" @change="knowledge(index)">
          <el-option
            v-for="item in status.knowledge"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <div style="font-size: 29px;" @click="addKnowledge()">+</div>
      </el-form-item>
      <el-form-item style="margin-top: 10px;">
        <el-button type="primary" @click="onSubmit('formItem')" plain>提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
import VueUeditorWrap from 'vue-ueditor-wrap'
import { ueditorPaper } from '@/plugins/pluginsConfig'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  components: {
    VueUeditorWrap
  },
  data () {
    return {
      formItem: {
        item_content: '',
        question_id: 0,
        knowledge_id: [0],
        content: '',
        diff: 3,
        answer: '',
        score: 0
      },
      status: {
        knowledge: []
      },
      myConfig: ueditorPaper
    }
  },
  methods: {
    addKnowledge () {
      if (this.formItem.knowledge_id.indexOf(0) === -1) {
        this.formItem.knowledge_id.push(0)
      }
    },
    knowledge (index) {
      const knowledgeId = this.formItem.knowledge_id[index]
      const tmp = []
      for (let i = 0; i < this.formItem.knowledge_id.length; i++) {
        if (i === index) continue
        if (this.formItem.knowledge_id[i] === knowledgeId) {
          tmp.push(i)
        }
      }
      for (let i = 0; i < tmp.length; i++) {
        this.formItem.knowledge_id.splice(tmp[i], 1)
      }
    },
    onSubmit () {
      this.$refs.formItem.validate((valid) => {
        if (valid) {
          this.$msgbox({
            title: this.$t('normal.tip'),
            message: this.$t('normal.tipDes'),
            confirmButtonText: this.$t('button.confirm'),
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true
                instance.confirmButtonText = this.$t('normal.process')
                instance.title = this.$t('normal.processTitle')
                instance.message = this.$t('normal.processMessage')
                setTimeout(() => {
                  done()
                  setTimeout(() => {
                    instance.confirmButtonLoading = false
                  }, 300)
                }, 1000)
              } else {
                done()
              }
            }
          }).then(action => {
            if (action === 'confirm') {
              this.postFetch('/api/question_item/add', this.formItem, { needSuccess: true }).then((response) => {
                this.$router.replace({ path: '/question_item/index', query: this.$route.query })
              })
            }
          })
        } else {
          this.$message({
            message: this.$t('normal.validata'),
            type: 'error'
          })
        }
      })
    }
  },
  async created () {
    // eslint-disable-next-line
    uParse('.content', {
      'liiconpath': '/ueditor/themes/ueditor-list/'
    })
    this.status.knowledge = await this.$store.dispatch('getOption', 'knowledge')
    this.formItem.question_id = this.$route.query.question_id
  }
}
</script>
