<template>
  <div>
    <p v-for="(item, index) in buffInfo" :key="index">{{item}}</p>
    <el-form :model="formItem" ref="formItem" label-width="100px">
      <el-form-item label="上传文件">
        <el-upload
          action="/api/customer/addList"
          :on-success="handleSuccess">
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('formItem')" plain>提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  data () {
    return {
      filter: {},
      formItem: {},
      loading: false
    }
  },
  methods: {
    handleSuccess (file, fileList) {
      console.log(file, fileList)
      if (window.FileReader) {
        var fr = new FileReader()
        fr.onloadend = function (e) {
          console.log(e.target.result)
        }
      }
    },
    onSubmit (name) {
      this.postFetch('/api/' + this.controller + '/addList', this.formItem, { needSuccess: true }).then((response) => {
        this.$router.go(-1)
      })
    }
  },
  async created () {
  }
}
</script>
