<template>
  <div>
    <el-form ref="formItem" label-width="100px">
      <el-form-item :label="$t('normal.class')">
        <el-select v-model="classId" @change="changeValue">
          <el-option
            v-for="item in classList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div>没有提交的</div>
    <el-tag v-for="item in noReport" :key="item.student_id">{{item.class_name + item.name}}</el-tag>
    <el-form ref="formItem" label-width="100px" v-for="item in data" :key="item.student_id">
      <vue-qr :text="href + item.crypted"></vue-qr>
      {{href + item.crypted}}
      <el-form-item v-if="item.name" :label="$t('normal.name')">
        <div>{{item.class_name}}{{item.name}}</div>
      </el-form-item>
      <el-form-item :label="$t('normal.desc')">
        <div v-html="item.desc"></div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
import VueQr from 'vue-qr'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  components: {
    VueQr
  },
  data () {
    return {
      href: 'https://wechat.widenteen.com/word?w=',
      data: [],
      noReport: [],
      classList: [],
      classId: 13
    }
  },
  methods: {
    getData () {
      if (this.classId === 13 || this.classId === 0) {
        this.getFetch('/api/student_rate/getAll').then((response) => {
          this.data = response.data
          this.noReport = response.noReport
        })
      } else {
        this.getFetch('/api/student_rate/getAll', { class_id: this.classId }).then((response) => {
          this.data = response.data
          this.noReport = response.noReport
        })
      }
    },
    changeValue () {
      console.log(12321321)
      this.getData()
    }
  },
  async created () {
    this.classList = await this.$store.dispatch('getOption', 'classes')
    this.getData()
  }
}
</script>
