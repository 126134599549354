<template>
  <div>
    <el-date-picker
      value-format="yyyy-MM-dd HH:mm:ss"
      v-model="formItem.date"
      :picker-options="pickerOptions"
      type="datetimerange"
      range-separator="-"
      @change="getData">
    </el-date-picker>
    <el-select
      v-model="formItem.come_in"
      @change="getData">
      <el-option
        v-for="item in orderListComeIn"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
    <el-collapse v-model="activeNames">
      <el-collapse-item title="总体数据统计" name="1">
        <el-row>
          <el-col :span="24">
            <el-table :data="lenghtOne" style="width: 100%">
              <el-table-column prop="one" label="接通率%"></el-table-column>
              <el-table-column prop="two" label="约访率%"></el-table-column>
              <el-table-column prop="three" label="到访率%"></el-table-column>
              <el-table-column prop="four" label="转化率%"></el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-collapse-item>
      <el-collapse-item title="个人数据统计" name="2">
        <el-row>
          <el-col :span="24">
            <el-table :data="data" style="width: 100%">
              <el-table-column prop="name" label="姓名"></el-table-column>
              <el-table-column prop="allNum" label="总数"></el-table-column>
              <el-table-column label="接通数">
                <template slot-scope="scope">
                  {{scope.row.allNum-scope.row.banNum}}
                </template>
              </el-table-column>
              <el-table-column prop="checkNum" label="约访数"></el-table-column>
              <el-table-column prop="comeNum" label="到访数"></el-table-column>
              <el-table-column prop="orderNum" label="转化数"></el-table-column>
              <el-table-column label="接通率">
                <template slot-scope="scope">
                  <div v-if="scope.row.allNum > 0">
                    {{((scope.row.allNum-scope.row.banNum)/scope.row.allNum*100).toFixed(2)}}%
                  </div>
                  <div v-else>0.00%</div>
                </template>
              </el-table-column>
              <el-table-column label="约访率">
                <template slot-scope="scope">
                  <div v-if="scope.row.allNum > 0">
                    {{(scope.row.checkNum/scope.row.allNum*100).toFixed(2)}}%
                  </div>
                  <div v-else>0.00%</div>
                </template>
              </el-table-column>
              <el-table-column label="到访率">
                <template slot-scope="scope">
                  <div v-if="scope.row.checkNum > 0">
                    {{(scope.row.comeNum/scope.row.checkNum*100).toFixed(2)}}%
                  </div>
                  <div v-else>0.00%</div>
                </template>
              </el-table-column>
              <el-table-column label="转化率">
                <template slot-scope="scope">
                  <div v-if="scope.row.comeNum > 0">
                    {{(scope.row.orderNum/scope.row.comeNum*100).toFixed(2)}}%
                  </div>
                  <div v-else>0.00%</div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
import zhStatus from '@/lang/zh_status'
import enStatus from '@/lang/en_status'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo',
      'lang'
    ])
  },
  data () {
    return {
      activeNames: ['1', '2'],
      formItem: {
        date: '',
        come_in: 0
      },
      data: [],
      orderListComeIn: [],
      lenghtOne: []
    }
  },
  methods: {
    getData () {
      this.getFetch('/api/order_list/statistic', this.formItem).then(response => {
        this.lenghtOne = []
        this.lenghtOne.push({
          one: (100 - response.stepOne.num / response.stepOne.all * 100).toFixed(2),
          two: (response.stepTwo.num / response.stepTwo.all * 100).toFixed(2),
          three: (response.stepThree.num / response.stepThree.all * 100).toFixed(2),
          four: (response.stepFour.num / response.stepFour.all * 100).toFixed(2)
        })

        const newList = []
        for (let i in response.stepTwo.allUser) {
          const ov = {
            name: response.stepOne.allUser[i].username,
            allNum: 0,
            banNum: 0,
            checkNum: 0,
            comeNum: 0,
            orderNum: 0
          }
          if (typeof response.stepOne.allUser[i] === 'object') {
            ov.allNum = response.stepOne.allUser[i].num
          }
          if (typeof response.stepOne.numUser[i] === 'object') {
            ov.banNum = response.stepOne.numUser[i].num
          }
          if (typeof response.stepTwo.numUser[i] === 'object') {
            ov.checkNum = response.stepTwo.numUser[i].num
          }
          if (typeof response.stepThree.numUser[i] === 'object') {
            ov.comeNum = response.stepThree.numUser[i].num
          }
          if (typeof response.stepFour.numUser[i] === 'object') {
            ov.orderNum = response.stepFour.numUser[i].num
          }
          newList.push(ov)
        }
        this.data = newList
      })
    }
  },
  async created () {
    this.getData()
    switch (this.lang) {
      case 'zh':
        this.orderListComeIn = [ ...zhStatus.orderListComeIn ]
        break
      case 'en':
        this.orderListComeIn = [ ...enStatus.orderListComeIn ]
        break
    }
    this.orderListComeIn.unshift({
      label: '全部',
      value: 0
    })
    let props = this.$route.matched[0].props
    if (!this.$isEmpty(props)) {
      props = props.default
      if (!this.permissionCheck(props.permission)) {
        this.$message.error(this.$t('error.1'))
      } else {
        for (let prop in props) {
          switch (prop) {
            case 'buttonItems':
              for (let item in props.buttonItems) {
                if (typeof props.buttonItems[item].display === 'object' && (!this.permissionCheck(props.buttonItems[item].display))) continue
                this.buttonItems.push(props.buttonItems[item])
              }
              break
          }
        }
      }
    }
  }
}
</script>

<style lang="css">
  .line-one{
    height: 22px;
  }
</style>
