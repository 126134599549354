<template>
  <div>
    <el-form :model="formItem" :rules="rules" ref="formItem" label-width="200px">
      <el-form-item :label="$t('normal.name')" prop="name">
        <el-input v-model="formItem.name" @blur="buffValue"></el-input>
      </el-form-item>
      <el-form-item :label="$t('normal.post')">
        <el-select v-model="formItem.post" @change="buffValue">
          <el-option
            v-for="item in status.post"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('normal.identity_id')" prop="identity">
        <el-input v-model="formItem.identity" @blur="buffValue"></el-input>
      </el-form-item>
      <el-form-item :label="$t('resume.isReproduction')">
        <el-switch v-model="formItem.isReproduction" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </el-form-item>
      <el-form-item :label="$t('resume.isMarried')">
        <el-switch v-model="formItem.isMarried" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </el-form-item>
      <el-form-item :label="$t('resume.politicsState')" prop="politicsState">
        <el-input v-model="formItem.politicsState" @blur="buffValue"></el-input>
      </el-form-item>
      <el-form-item :label="$t('resume.speciality')" prop="speciality">
        <el-tag :key="tip" v-for="tip in formItem.speciality" closable :disable-transitions="false" @close="handleClose(tip, 'speciality')">
          {{tip}}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="specialityVisible"
          v-model="inputValue"
          ref="saveTagInputSpeciality"
          size="small"
          @keyup.enter.native="handleInputConfirmSpeciality"
          @blur="handleInputConfirmSpeciality">
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInputSpeciality">{{$t('normal.add')}}</el-button>
      </el-form-item>
      <el-form-item :label="$t('resume.computerLevel')" prop="computerLevel">
        <el-input v-model="formItem.computerLevel" @blur="buffValue"></el-input>
      </el-form-item>
      <el-form-item :label="$t('resume.englishLevel')" prop="englishLevel">
        <el-input v-model="formItem.englishLevel" @blur="buffValue"></el-input>
      </el-form-item>
      <el-form-item :label="$t('resume.tips')" prop="tips">
        <el-tag :key="tip" v-for="tip in formItem.tips" closable :disable-transitions="false" @close="handleClose(tip, 'tips')">
          {{tip}}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm">
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput">{{$t('normal.add')}}</el-button>
      </el-form-item>
      <el-form-item :label="$t('normal.tel')" prop="tel">
        <el-input v-model="formItem.tel" @blur="buffValue"></el-input>
      </el-form-item>
      <el-form-item label="qq" prop="qq">
        <el-input v-model="formItem.qq" @blur="buffValue"></el-input>
      </el-form-item>
      <el-form-item :label="$t('normal.wechat')" prop="wechat">
        <el-input v-model="formItem.wechat" @blur="buffValue"></el-input>
      </el-form-item>
      <el-form-item :label="$t('normal.email')" prop="email">
        <el-input v-model="formItem.email" @blur="buffValue"></el-input>
      </el-form-item>
      <el-form-item :label="$t('resume.emergencyContact.name')" prop="emergencyContactName">
        <el-input v-model="formItem.emergencyContact.name" @blur="buffValue"></el-input>
      </el-form-item>
      <el-form-item :label="$t('resume.emergencyContact.tel')" prop="emergencyContactTel">
        <el-input v-model="formItem.emergencyContact.tel" @blur="buffValue"></el-input>
      </el-form-item>
      <el-form-item :label="$t('normal.address')" prop="address">
        <el-input v-model="formItem.address" @blur="buffValue"></el-input>
      </el-form-item>
      <el-form-item :label="$t('resume.qualifications')" prop="qualifications">
        <el-row style="text-align: center;">
          <el-col :span="8">{{$t('normal.school')}}</el-col>
          <el-col :span="8">{{$t('resume.major')}}</el-col>
          <el-col :span="8">{{$t('normal.time')}}</el-col>
        </el-row>
        <el-row style="text-align: center;" v-for="(item, index) in formItem.qualifications" :key="index">
          <el-col :span="8"><el-input v-model="item.schoolName"></el-input></el-col>
          <el-col :span="8"><el-input v-model="item.major"></el-input></el-col>
          <el-col :span="8"><el-date-picker v-model="item.date" type="date" value-format="yyyy-MM-dd"></el-date-picker></el-col>
        </el-row>
        <el-row style="text-align: center;" v-if="formItem.qualifications.length < 2">
          <i class="icon-addition_fill" @click="addTable('qualifications')"></i>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('resume.families')" prop="families">
        <el-row style="text-align: center;">
          <el-col :span="12">{{$t('normal.name')}}</el-col>
          <el-col :span="12">{{$t('resume.relationship')}}</el-col>
        </el-row>
        <el-row style="text-align: center;" v-for="(item, index) in formItem.families" :key="index">
          <el-col :span="12"><el-input v-model="item.name"></el-input></el-col>
          <el-col :span="12"><el-input v-model="item.relationship"></el-input></el-col>
        </el-row>
        <el-row style="text-align: center;">
          <i class="icon-addition_fill" @click="addTable('families')"></i>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('resume.studyExperience')" prop="studyExperience">
        <el-row style="text-align: center;">
          <el-col :span="6">{{$t('resume.beginDate')}}</el-col>
          <el-col :span="6">{{$t('resume.endDate')}}</el-col>
          <el-col :span="6">{{$t('normal.school')}}</el-col>
          <el-col :span="6">{{$t('resume.post')}}</el-col>
        </el-row>
        <el-row style="text-align: center;" v-for="(item, index) in formItem.studyExperience" :key="index">
          <el-col :span="6"><el-date-picker v-model="item.beginDate" type="date" value-format="yyyy-MM-dd"></el-date-picker></el-col>
          <el-col :span="6"><el-date-picker v-model="item.endDate" type="date" value-format="yyyy-MM-dd"></el-date-picker></el-col>
          <el-col :span="6"><el-input v-model="item.name"></el-input></el-col>
          <el-col :span="6"><el-input v-model="item.post"></el-input></el-col>
        </el-row>
        <el-row style="text-align: center;">
          <i class="icon-addition_fill" @click="addTable('studyExperience')"></i>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('resume.careerExperience')" prop="careerExperience">
        <el-row style="text-align: center;">
          <el-col :span="6">{{$t('resume.beginDate')}}</el-col>
          <el-col :span="6">{{$t('resume.endDate')}}</el-col>
          <el-col :span="6">{{$t('normal.company')}}</el-col>
          <el-col :span="6">{{$t('resume.post')}}</el-col>
        </el-row>
        <el-row style="text-align: center;" v-for="(item, index) in formItem.careerExperience" :key="index">
          <el-col :span="6"><el-date-picker v-model="item.beginDate" type="date" value-format="yyyy-MM-dd"></el-date-picker></el-col>
          <el-col :span="6"><el-date-picker v-model="item.endDate" type="date" value-format="yyyy-MM-dd"></el-date-picker></el-col>
          <el-col :span="6"><el-input v-model="item.name"></el-input></el-col>
          <el-col :span="6"><el-input v-model="item.post"></el-input></el-col>
        </el-row>
        <el-row style="text-align: center;">
          <i class="icon-addition_fill" @click="addTable('careerExperience')"></i>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('normal.headimgurl')" prop="headimgurl">
        <el-upload
          :data="{ name: 'headimgurl' }"
          action="/api/upload/index"
          list-type="picture-card"
          :multiple="false"
          :limit="1"
          :on-success="handleSuccess">
          <i class="icon-addition_fill"></i>
        </el-upload>
      </el-form-item>
      <el-form-item :label="$t('resume.information')" prop="picture">
        <el-upload
          :data="{ name: 'picture' }"
          action="/api/upload/index"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :on-exceed="toManayFile"
          :on-success="handleSuccess">
          <i class="icon-addition_fill"></i>
        </el-upload>
      </el-form-item>
      <el-form-item :label="$t('resume.careerPlanning')" prop="careerPlanning">
        <el-input type="textarea" v-model="formItem.careerPlanning" @blur="buffValue"></el-input>
      </el-form-item>
      <el-form-item :label="$t('resume.classSpecial')" prop="classSpecial">
        <el-input type="textarea" v-model="formItem.classSpecial" @blur="buffValue"></el-input>
      </el-form-item>
      <el-form-item :label="$t('resume.wages')" prop="wages">
        <el-input v-model="formItem.wages" @blur="buffValue"></el-input>
      </el-form-item>
      <el-form-item :label="$t('resume.workDate')" prop="workDate">
        <el-date-picker v-model="formItem.workDate" type="date" value-format="yyyy-MM-dd" @change="buffValue"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('formItem')" plain>提交</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import { status } from '@/plugins/permission'
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  data () {
    const o = this.$route.path.split('/')
    return {
      inputValue: '',
      inputVisible: false,
      specialityVisible: false,
      status,
      controller: (o[1] ? o[1] : undefined),
      method: (o[2] ? o[2] : undefined),
      formItem: {
        faith: '',
        name: '',
        post: 1,
        qualifications: [{
          schoolName: '',
          major: '',
          date: ''
        }],
        identity: '',
        isReproduction: 0,
        isMarried: 0,
        politicsState: '',
        speciality: [],
        computerLevel: '',
        englishLevel: '',
        tips: [],
        tel: '',
        qq: '',
        wechat: '',
        email: '',
        emergencyContact: {
          name: '',
          tel: ''
        },
        address: '',
        families: [{
          name: '',
          relationship: ''
        }],
        studyExperience: [{
          beginDate: '',
          endDate: '',
          name: '',
          post: ''
        }],
        careerExperience: [{
          beginDate: '',
          endDate: '',
          name: '',
          post: ''
        }],
        headimgurl: [],
        picture: [],
        careerPlanning: '',
        classSpecial: '',
        wages: '',
        workDate: ''
      },
      formItems: [],
      rules: this.$validata.getRules([
        { value: [{ required: true, message: { text: 'formRule.normal.required', msg: 'normal.name' }, trigger: 'blur' }], labal: 'name' },
        { value: [{ required: true, message: { text: 'formRule.normal.required', msg: 'normal.identity_id' }, trigger: 'blur' }], labal: 'identity' },
        { value: [{ required: true, message: { text: 'formRule.normal.required', msg: 'resume.politicsState' }, trigger: 'blur' }], labal: 'politicsState' },
        { value: [{ required: true, message: { text: 'formRule.normal.required', msg: 'normal.address' }, trigger: 'blur' }], labal: 'address' },
        { value: [{ required: true, message: { text: 'formRule.normal.required', msg: 'resume.careerPlanning' }, trigger: 'blur' }], labal: 'careerPlanning' },
        { value: [{ required: true, message: { text: 'formRule.normal.required', msg: 'resume.wages' }, trigger: 'blur' }], labal: 'wages' },
        { value: [{ required: true, message: { text: 'formRule.normal.required', msg: 'resume.workDate' }, trigger: 'blur' }], labal: 'workDate' },
        { value: [{ required: true, message: { text: 'formRule.normal.required', msg: 'resume.headimgurl' }, trigger: 'blur' }], labal: 'headimgurl' },
        { value: 'tel', labal: 'tel' },
        { value: 'email', labal: 'email' }
      ]),
      dialogImageUrl: '',
      dialogVisible: false,
      loading: false
    }
  },
  methods: {
    addTable (value) {
      switch (value) {
        case 'families':
          this.formItem[value].push({
            name: '',
            relationship: ''
          })
          break
        case 'studyExperience':
          this.formItem[value].push({
            beginDate: '',
            endDate: '',
            name: '',
            post: ''
          })
          break
        case 'careerExperience':
          this.formItem[value].push({
            beginDate: '',
            endDate: '',
            name: '',
            post: ''
          })
          break
        case 'qualifications':
          this.formItem[value].push({
            schoolName: '',
            date: '',
            major: ''
          })
          break
      }
    },
    showInput () {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    showInputSpeciality () {
      this.specialityVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInputSpeciality.$refs.input.focus()
      })
    },
    handleInputConfirmSpeciality () {
      let inputValue = this.inputValue
      if (inputValue) {
        this.formItem.speciality.push(inputValue)
      }
      this.specialityVisible = false
      this.inputValue = ''
      this.buffValue()
    },
    handleInputConfirm () {
      let inputValue = this.inputValue
      if (inputValue) {
        this.formItem.tips.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
      this.buffValue()
    },
    handleClose (tag, value) {
      this.formItem[value].splice(this.formItem[value].indexOf(tag), 1)
      this.buffValue()
    },
    toManayFile () {
      this.$message({
        message: this.$t('normal.fileLimit')
      })
    },
    handleSuccess (file, fileList) {
      this.formItem[file.data.name].push(file.data.respData.key)
      this.buffValue()
    },
    handleRemove (file, fileList) {
      this.formItem[file.response.data.name] = []
      for (let fileItem in fileList) {
        this.formItem[file.response.data.name].push(fileList[fileItem].response.data.respData.key)
      }
      this.buffValue()
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    buffValue () {
      const item = this.controller + this.method
      this.$localStorage.set(item, JSON.stringify(this.formItem))
    },
    onSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$msgbox({
            title: this.$t('normal.tip'),
            message: this.$t('normal.tipDes'),
            confirmButtonText: this.$t('button.confirm'),
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true
                instance.confirmButtonText = this.$t('normal.process')
                instance.title = this.$t('normal.processTitle')
                instance.message = this.$t('normal.processMessage')
                setTimeout(() => {
                  done()
                  setTimeout(() => {
                    instance.confirmButtonLoading = false
                  }, 300)
                }, 1000)
              } else {
                done()
              }
            }
          }).then(action => {
            if (action === 'confirm') {
              this.postFetch('/api/' + this.controller + '/add', { data: JSON.stringify(this.formItem) }, { needSuccess: true }).then((response) => {
                // this.$router.replace('/' + this.controller + '/index')
              })
            }
          })
        } else {
          this.$message({
            message: this.$t('normal.validata'),
            type: 'error'
          })
        }
      })
    }
  },
  async created () {
    this.status.post = await this.$store.dispatch('getOption', 'resume_post')
    const item = this.controller + this.method
    this.formItem = { ...this.formItem, ...JSON.parse(this.$localStorage.get(item)) }
  }
}
</script>
