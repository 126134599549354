<template>
  <div>
    <el-button type="primary" @click.native.prevent="download()" plain>下载</el-button>
    <div id="saf" class="WordSection1">
      <div v-for="item in data.item" :key="item.exam_item_id" :id="item.exam_item_id">
        <div :caclu="num=0">{{item.name}}(总分：{{item.questions.score}})</div>
        <div v-for="question in item.questions.questions" :key="question.question_id">
          <div :caclu="num++"></div>
          <p><span style="border:3px solid;border-color: #A8FF24;">{{num}}:&nbsp;&nbsp;</span><span>{{checkOption(question.question_from, status.questionFrom) + question.question_to}}</span></p>
          <div v-html="question.title"></div>
          <div v-for="questionItem in question.items" :key="questionItem.item_id">
            <span>分数({{questionItem.score}})</span><span v-html="questionItem.item_content"></span>
          </div>
        </div>
        <span>@page@</span>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import { status } from '@/plugins/permission'
import { saveAs } from 'file-saver'
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  data () {
    return {
      data: [],
      status: {
        questionFrom: []
      }
    }
  },
  methods: {
    checkOption (value, option) {
      for (let i = 0; i < option.length; i++) {
        if (option[i].value === value) {
          return option[i].label
        }
      }
    },
    download: function () {
      $('#saf').wordExport(this.data.examType.created_at)
      // for (let i = 0; i < this.data.item.length; i++) {
      //   console.log(this.data.item[i].name)
      //   if (this.data.item[i].name) {
      //     $('#' + this.data.item[i].exam_item_id).wordExport(this.data.examType.created_at + this.data.item[i].name)
      //   } else {
      //     $('#' + this.data.item[i].exam_item_id).wordExport(this.data.examType.created_at + this.data.item[i].exam_item_id)
      //   }
      // }
    }
  },
  async created () {
    $.fn.wordExport = function (fileName, isbase64 = false) {
      var statics = {
        mhtml: {
          /* eslint-disable */
          top: 'Mime-Version: 1.0\nContent-Base: ' + location.href + '\nContent-Type: Multipart/related; boundary=\"NEXT.ITEM-BOUNDARY\";type=\"text/html\"\n\n--NEXT.ITEM-BOUNDARY\nContent-Type: text/html; charset=\"utf-8\"\nContent-Location: ' + location.href + '\n\n<!DOCTYPE html>\n<html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:w="urn:schemas-microsoft-com:office:word" xmlns:m="http://schemas.microsoft.com/office/2004/12/omml" xmlns="http://www.w3.org/TR/REC-html40">\n_html_</html>',
          /* eslint-disable */
          head: `132321<head>\n<meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\">\n<style>\n_styles_\n</style>\n
            <!--[if gte mso 9]><xml>
            <w:WordDocument>
              <w:View>Print</w:View>
              <w:GrammarState>Clean</w:GrammarState>
              <w:TrackMoves>false</w:TrackMoves>
              <w:TrackFormatting/>
              <w:ValidateAgainstSchemas/>
              <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
              <w:IgnoreMixedContent>false</w:IgnoreMixedContent>
              <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
              <w:DoNotPromoteQF/>
              <w:LidThemeOther>EN-US</w:LidThemeOther>
              <w:LidThemeAsian>ZH-CN</w:LidThemeAsian>
              <w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript>
              <w:Compatibility>
              <w:BreakWrappedTables/>
              <w:SnapToGridInCell/>
              <w:WrapTextWithPunct/>
              <w:UseAsianBreakRules/>
              <w:DontGrowAutofit/>
              <w:SplitPgBreakAndParaMark/>
              <w:DontVertAlignCellWithSp/>
              <w:DontBreakConstrainedForcedTables/>
              <w:DontVertAlignInTxbx/>
              <w:Word11KerningPairs/>
              <w:CachedColBalance/>
              <w:UseFELayout/>
              </w:Compatibility>
              <w:BrowserLevel>MicrosoftInternetExplorer4</w:BrowserLevel>
              <m:mathPr>
              <m:mathFont m:val="Cambria Math"/>
              <m:brkBin m:val="before"/>
              <m:brkBinSub m:val="--"/>
              <m:smallFrac m:val="off"/>
              <m:dispDef/>
              <m:lMargin m:val="0"/>
              <m:rMargin m:val="0"/>
              <m:defJc m:val="centerGroup"/>
              <m:wrapIndent m:val="1440"/>
              <m:intLim m:val="subSup"/>
              <m:naryLim m:val="undOvr"/>
              </m:mathPr></w:WordDocument>
            </xml><![endif]--></head>\n`,
          body: '<body>_body_</body>'
        }
      }
      var options = {
        maxWidth: 624
      }
      // Clone selected element before manipulating it
      var markup = $(this).clone()

      // Remove hidden elements from the output
      markup.each(function () {
        var self = $(this)
        if (self.is(':hidden')) self.remove()
      })

      // Embed all images using Data URLs
      var images = []
      var img = markup.find('img')
      if (isbase64) {
        for (let i = 0; i < img.length; i++) {
          let uri = img[i].src
          images[i] = {
            type: uri.substring(uri.indexOf(':') + 1, uri.indexOf(';')),
            encoding: uri.substring(uri.indexOf(';') + 1, uri.indexOf(',')),
            location: uri,
            data: uri.substring(uri.indexOf(',') + 1)
          }
        }
      } else {
        for (let i = 0; i < img.length; i++) {
          // Calculate dimensions of output image
          var w = Math.min(img[i].width, options.maxWidth)
          var h = img[i].height * (w / img[i].width)
          // Create canvas for converting image to data URL
          var canvas = document.createElement('CANVAS')
          canvas.width = w
          canvas.height = h
          // Draw image to canvas
          var context = canvas.getContext('2d')
          context.drawImage(img[i], 0, 0, w, h)
          // Get data URL encoding of image
          let uri = canvas.toDataURL('image/png')
          $(img[i]).attr('src', img[i].src)
          img[i].width = w
          img[i].height = h
          // Save encoded image to array
          images[i] = {
            type: uri.substring(uri.indexOf(':') + 1, uri.indexOf(';')),
            encoding: uri.substring(uri.indexOf(';') + 1, uri.indexOf(',')),
            location: $(img[i]).attr('src'),
            data: uri.substring(uri.indexOf(',') + 1)
          }
        }
      }

      // Prepare bottom of mhtml file with image data
      var mhtmlBottom = '\n'
      for (let i = 0; i < images.length; i++) {
        mhtmlBottom += '--NEXT.ITEM-BOUNDARY\n'
        mhtmlBottom += 'Content-Location: ' + images[i].location + '\n'
        mhtmlBottom += 'Content-Type: ' + images[i].type + '\n'
        mhtmlBottom += 'Content-Transfer-Encoding: ' + images[i].encoding + '\n\n'
        mhtmlBottom += images[i].data + '\n\n'
      }
      mhtmlBottom += '--NEXT.ITEM-BOUNDARY--'

      // TODO: load css from included stylesheet
      var styles = ''

      // Aggregate parts of the file together
      var fileContent = statics.mhtml.top.replace('_html_', statics.mhtml.head.replace('_styles_', styles) + statics.mhtml.body.replace('_body_', markup.html())) + mhtmlBottom

      // Create a Blob with the file contents
      var blob = new Blob([fileContent], {
        type: 'application/msword;charset=utf-8'
      })
      saveAs(blob, fileName + '.doc')
    }
    this.status.questionFrom = status.questionFrom
    this.data = await this.getFetch('/api/examination/paper', { id: this.$route.query.id })
  }
}
</script>

<style lang="css">
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:30.0pt 30.0pt 30.0pt 30.0pt;
	mso-header-margin:42.55pt;
	mso-footer-margin:49.6pt;
	mso-paper-source:0;}
div.WordSection1
	{page:WordSection1;}
</style>
